import { Navigate } from 'react-router-dom';
import { ParkingaboLogoHeaderCompact } from '../../components/layout/ParkingaboLogoHeader.tsx';
import { Box, Link, Stack, Typography } from '@mui/material';
import { Localized } from 'dg-web-shared/common/hooks/LanguageProvider.tsx';
import { ParkingaboScrollableLayout } from '../../components/layout/ParkingaboLayout.tsx';
import { ParkingaboCardButton } from '../../components/layout/ParkingaboCardButton.tsx';

type TenantSelectionOption = {
    tenantId: number;
    tenantName: string;
};

export function TenantSelectionRoute({
    options,
    logout,
}: {
    options: TenantSelectionOption[];
    logout: () => void;
}) {
    if (options.length === 0) {
        return (
            <ParkingaboScrollableLayout>
                <ParkingaboLogoHeaderCompact />
                <Box
                    sx={{
                        flexGrow: 1,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Localized
                        de="Ihr Login ist mit keinem Konto verknüpft."
                        fr="Votre connexion n'est associée à aucun compte."
                        it="Il tuo login non è associato a nessun account."
                        en="Your login is not linked to any account."
                    />
                </Box>
            </ParkingaboScrollableLayout>
        );
    }

    if (options.length === 1) {
        return <Navigate to={`${options[0].tenantId}`} replace />;
    }

    return (
        <ParkingaboScrollableLayout>
            <ParkingaboLogoHeaderCompact />
            <Box sx={{ padding: theme => theme.spacing(4) }}>
                <Typography
                    variant="h1"
                    color="inherit"
                    sx={theme => ({
                        paddingTop: theme.spacing(1),
                        paddingBottom: theme.spacing(3),
                        textAlign: 'center',
                        marginBottom: theme.spacing(1),
                    })}
                >
                    <Localized
                        de="Kontoauswahl"
                        fr="Sélection du compte"
                        it="Selezione account"
                        en="Account selection"
                    />
                </Typography>
                <Stack spacing={2}>
                    {options.map(option => (
                        <ParkingaboCardButton
                            key={option.tenantId}
                            to={`${option.tenantId}`}
                            label={option.tenantName}
                        />
                    ))}
                </Stack>
                <Box
                    sx={theme => ({
                        paddingTop: theme.spacing(4),
                        textAlign: 'center',
                    })}
                >
                    <Link onClick={logout} underline="always">
                        <Localized
                            de="Abmelden"
                            fr="Déconnecter"
                            it="Esci"
                            en="Logout"
                        />
                    </Link>
                </Box>
            </Box>
        </ParkingaboScrollableLayout>
    );
}
