import { Box, Card, CardActionArea, Stack, Typography } from '@mui/material';
import { Add, ChevronRight } from '@mui/icons-material';

import { Outlet, useNavigate } from 'react-router-dom';
import { VehicleTypeIcon } from 'dg-web-shared/common/components/material-ui/VehicleTypeIcon';
import { Localized } from 'dg-web-shared/common/hooks/LanguageProvider';
import { ParkingaboLayoutWithHeader } from '../../../components/layout/ParkingaboLayoutWithHeader';
import { VehicleQrCodeDrawer } from '../../../components/VehicleQrCodeDrawer';
import {
    ParkingaboVehicle,
    VehicleLicensePlateType,
} from 'dg-web-shared/common/models/Vehicle';
import {
    formatBadgeLabelByType,
    isLicensePlateExpected,
} from 'product-shared/tenant/TenantVehicleIdentificationUtils.ts';
import { TenantAllowedBarrierGateVehicleIdentification } from 'dg-web-shared/model/TenantEnums.ts';
import { useCustomerTenant } from '../../../components/CustomerTenantProvider.tsx';
import { Tenant } from '../../../shared/ParkingaboModels.tsx';

export function VehiclesRoute() {
    const { tenant, vehicles } = useCustomerTenant();
    return (
        <ParkingaboLayoutWithHeader
            title={
                <Localized
                    de="Fahrzeuge"
                    fr="Véhicules"
                    it="Veicoli"
                    en="Vehicles"
                />
            }
            backTo={'..'}
        >
            <Stack
                direction="column"
                justifyContent="flex-start"
                alignItems="stretch"
                spacing={2}
            >
                {vehicles.data.map(vehicle => (
                    <VehicleCard
                        key={vehicle.licensePlateNr}
                        tenant={tenant}
                        vehicle={vehicle}
                    />
                ))}
                <AddVehicleCard />
            </Stack>

            <Outlet />

            {tenant.allowedBarrierGateVehicleIdentification ===
                TenantAllowedBarrierGateVehicleIdentification.LICENSE_PLATE_QR && (
                <VehicleQrCodeDrawer vehicles={vehicles.data} />
            )}
        </ParkingaboLayoutWithHeader>
    );
}

function VehicleCard({
    vehicle,
    tenant,
}: {
    vehicle: ParkingaboVehicle;
    tenant: Tenant;
}) {
    const navigate = useNavigate();
    const lpMissing =
        isLicensePlateExpected(
            tenant.allowedEnforcedVehicleIdentification,
            tenant.allowedBarrierGateVehicleIdentification,
        ) && !vehicle.licensePlateNr;
    return (
        <BaseCard
            onClick={() => {
                navigate(vehicle.customerTenantCarId);
            }}
        >
            <VehicleTypeIcon
                type={vehicle.type || VehicleLicensePlateType.CAR}
                sx={theme => {
                    const h3FontSize = theme.typography.h3.fontSize;
                    return {
                        fontSize:
                            typeof h3FontSize === 'number'
                                ? h3FontSize * 2
                                : theme.typography.h2.fontSize,
                    };
                }}
            />
            <Box sx={{ flexGrow: 1 }}>
                <Typography fontWeight="bold">{vehicle.description}</Typography>
                {lpMissing ? (
                    <Typography
                        sx={{
                            color: theme => theme.palette.error.main,
                            fontStyle: 'italic',
                        }}
                    >
                        <Localized
                            de="KENNZEICHEN FEHLT"
                            fr="PLAQUE MANQUANTE"
                            it="TARGA MANCANTE"
                            en="MISSING LP"
                        />
                    </Typography>
                ) : (
                    <Typography>
                        {vehicle.licensePlateNr
                            ? vehicle.licensePlateNr
                            : formatBadgeLabelByType(
                                  vehicle.badgeLabelNr,
                                  vehicle.badgeType,
                              )}
                        {vehicle.country && ` (${vehicle.country})`}
                    </Typography>
                )}
            </Box>
            <ChevronRight />
        </BaseCard>
    );
}

function AddVehicleCard() {
    const navigate = useNavigate();
    return (
        <BaseCard onClick={() => navigate('add')}>
            <Typography
                component="div"
                flexGrow={1}
                textTransform="uppercase"
                fontWeight="bold"
            >
                <Localized
                    de="Fahrzeug hinzufügen"
                    fr="Ajouter un véhicule"
                    it="Aggiungi veicolo"
                    en="Add vehicle"
                />
            </Typography>
            <Add />
        </BaseCard>
    );
}

function BaseCard({
    onClick,
    children,
}: {
    onClick: () => void;
    children: React.ReactNode;
}) {
    return (
        <Card>
            <CardActionArea
                onClick={onClick}
                sx={theme => ({
                    padding: theme.spacing(2),
                    paddingRight: theme.spacing(3),
                    minHeight: theme.spacing(10),
                })}
            >
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={2}
                >
                    {children}
                </Stack>
            </CardActionArea>
        </Card>
    );
}
