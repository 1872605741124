import { useNavigate } from 'react-router-dom';
import { useParkingaboAuthedPathGeneration } from '../../RouteUtils';
import { FeedbackPopup } from '../../../components/FeedbackPopup';
import { Localized } from 'dg-web-shared/common/hooks/LanguageProvider';
import { currencyCentsToLocalPrice } from 'dg-web-shared/lib/NumberFormatter.ts';
import { useCustomerTenant } from '../../../components/CustomerTenantProvider.tsx';

export function NewPaymentRegistrationRequiredRoute() {
    return (
        <RedirectToPaymentRegistrationModal
            title={
                <Localized
                    de="Zahlungsmittel fehlt"
                    fr="Moyen de paiement manquant"
                    it="Mezzo di pagamento mancante"
                    en="Missing payment mean"
                />
            }
            body={
                <p>
                    <Localized
                        de="Bevor Sie ein neues Produkt hinzufügen können, müssen Sie ein gültiges Zahlungsmittel registrieren."
                        fr="Avant de pouvoir ajouter un nouveau produit, vous devez enregistrer un moyen de paiement valide."
                        it="Prima di poter aggiungere un nuovo prodotto, deve registrare un mezzo di pagamento valido."
                        en="Before you can add a new product, you must register a valid means of payment."
                    />
                </p>
            }
        />
    );
}

export function PaymentOverdueRegistrationRequiredRoute() {
    const { user } = useCustomerTenant();
    return (
        <RedirectToPaymentRegistrationModal
            title={
                <Localized
                    de="Zahlungsverzug"
                    fr="Paiements en souffrance"
                    it="Pagamenti in arretrato"
                    en="Overdue payments"
                />
            }
            body={
                <>
                    <p>
                        <Localized
                            de={`Bevor Sie ein neues Produkt hinzufügen können, müssen Sie Ihre überfällige Zahlung in Höhe von ${currencyCentsToLocalPrice(
                                'de',
                                user.overduePaymentsAmountRappen ?? 0,
                            )} begleichen.`}
                            fr={`Avant de pouvoir ajouter un nouveau produit, vous devez effacer votre paiement en retard de ${currencyCentsToLocalPrice(
                                'fr',
                                user.overduePaymentsAmountRappen ?? 0,
                            )}.`}
                            it={`Prima di poter aggiungere un nuovo prodotto, devi saldare il pagamento scaduto di ${currencyCentsToLocalPrice(
                                'it',
                                user.overduePaymentsAmountRappen ?? 0,
                            )}.`}
                            en={`Before you can add a new product, you must clear your overdue payment of ${currencyCentsToLocalPrice(
                                'en',
                                user.overduePaymentsAmountRappen ?? 0,
                            )}.`}
                        />
                    </p>
                    <p>
                        <Localized
                            de="Wenn Sie auf «Weiter» klicken, können Sie den gesamten negativen Saldo Ihres Kontos ausgleichen (einschließlich allfälliger zwischenzeitlicher neuer Belastungen)."
                            fr="En cliquant sur «Continuer», vous pouvez régler la totalité du solde négatif de votre compte (y compris les nouveaux débits intervenus entre-temps)."
                            it="Cliccando su «Avanti» potrà saldare l’intero saldo negativo del suo conto (incl. nuovi addebiti sopraggiunti nel frattempo)."
                            en="By clicking on «Proceed» you can settle the entire negative balance of your account (including any new debits in the meantime)."
                        />
                    </p>
                </>
            }
        />
    );
}

function RedirectToPaymentRegistrationModal({
    title,
    body,
}: {
    title: JSX.Element;
    body: JSX.Element;
}) {
    const navigate = useNavigate();
    const generateAuthedParkingaboPath = useParkingaboAuthedPathGeneration();

    return (
        <FeedbackPopup
            open={true}
            color="warning"
            onAbort={() => {
                navigate(generateAuthedParkingaboPath('products'));
            }}
            abortLabel={
                <Localized
                    de="Abbrechen"
                    fr="Annuler"
                    it="Annulla"
                    en="Cancel"
                />
            }
            onConfirm={() => {
                navigate(generateAuthedParkingaboPath('payment/register'));
            }}
            confirmLabel={
                <Localized
                    de="Weiter"
                    fr="Continuer"
                    it="Avanti"
                    en="Proceed"
                />
            }
            title={title}
        >
            {body}
        </FeedbackPopup>
    );
}
