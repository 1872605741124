import { useNavigate } from 'react-router-dom';
import {
    LoadingSpinnerPresets,
    PresetLoadingSpinner,
} from 'dg-web-shared/common/components/material-ui/PresetLoadingSpinner.tsx';
import { FeedbackPopup } from '../../components/FeedbackPopup.tsx';
import { Localized } from 'dg-web-shared/common/hooks/LanguageProvider.tsx';
import {
    ServerRequestState,
    useServerErrorEffect,
    useServerSuccessEffect,
    useServerWrite,
} from 'dg-web-shared/lib/hooks/ServerStateHooks.ts';
import { ValidationData } from 'dg-web-shared/lib/forms/FormValidationHelpers.tsx';
import { useState } from 'react';

export enum ValidationState {
    LOADING = 'LOADING',
    REDIRECT_TO_LOGIN_ONBOARDING_COMPLETE = 'REDIRECT_TO_LOGIN_ONBOARDING_COMPLETE',
    REDIRECT_TO_LOGIN_ONBOARDING_PENDING = 'REDIRECT_TO_LOGIN_ONBOARDING_PENDING',
    CONFIRM_EMAIL = 'CONFIRM_EMAIL',
    NOT_FOUND = 'NOT_FOUND',
    GENERIC_ERROR = 'GENERIC_ERROR',
    ALREADY_REGISTERED_ON_OPERATOR = 'ALREADY_REGISTERED_ON_OPERATOR',
    EMAIL_NOT_VALID = 'EMAIL_NOT_VALID',
}

export enum ValidationOrigin {
    CUSTOMER = 'CUSTOMER',
    BADGE = 'BADGE',
    TENANT = 'TENANT',
}

export const enum PublicValidationState {
    NO_LOGIN = 'NO_LOGIN',
    EMAIL_CONFIRMATION_PENDING = 'EMAIL_CONFIRMATION_PENDING',
    ONBOARDING_PENDING = 'ONBOARDING_PENDING',
    ONBOARDING_COMPLETED = 'ONBOARDING_COMPLETED',
}

export interface PublicValidationResponse {
    validationState: PublicValidationState;
    email: string | null;
}

export enum PublicValidationErrorReason {
    NOT_FOUND = 'NOT_FOUND',
}

export enum AuthValidationErrorReason {
    NOT_FOUND = 'NOT_FOUND',
    ALREADY_REGISTERED_ON_OPERATOR = 'ALREADY_REGISTERED_ON_OPERATOR',
    EMAIL_NOT_VALID = 'EMAIL_NOT_VALID',
}

export enum AuthValidationSuccessResponse {
    LOGGED_IN = 'LOGGED_IN',
    LINK_LOGIN = 'LINK_LOGIN',
}

export type AuthValidationSuccess =
    | AuthSuccessLinkLoginResponse
    | AuthSuccessAlreadyLoggedInResponse;

export interface AuthValidationSuccessBase {
    response: AuthValidationSuccessResponse;
}

export interface AuthSuccessLinkLoginResponse
    extends AuthValidationSuccessBase {
    email: string;
    customerNr: string;
    operatorName: string;
    response: AuthValidationSuccessResponse.LINK_LOGIN;
}

export interface AuthSuccessAlreadyLoggedInResponse
    extends AuthValidationSuccessBase {
    response: AuthValidationSuccessResponse.LOGGED_IN;
    tenantId: number;
}

export function useResentVerificationMail() {
    const [anotherLinkState, requestAnotherLink] = useServerWrite<
        { email: string },
        never
    >(() => ({
        url: '/ui-api/parkingabo/resend-verification-link',
    }));
    return { anotherLinkState, requestAnotherLink };
}

export function AuthedValidationContent({
    validationState,
    origin,
}: {
    validationState: ValidationState;
    origin: ValidationOrigin;
}): JSX.Element | null {
    const navigate = useNavigate();

    switch (validationState) {
        case ValidationState.LOADING:
            return (
                <PresetLoadingSpinner
                    preset={LoadingSpinnerPresets.FillAllSpaceAndCenter}
                />
            );
        case ValidationState.ALREADY_REGISTERED_ON_OPERATOR:
            return (
                <AlreadyRegisteredError
                    onConfirm={() => navigate('/')}
                    origin={origin}
                />
            );
        case ValidationState.NOT_FOUND:
            return (
                <NotFoundPopup
                    onConfirm={() => navigate('/')}
                    origin={origin}
                />
            );
        case ValidationState.GENERIC_ERROR:
            return <GenericErrorPopup onConfirm={() => navigate('/')} />;
        case ValidationState.EMAIL_NOT_VALID:
            return <EmailNotValidPopup onConfirm={() => navigate('/')} />;
        case ValidationState.CONFIRM_EMAIL:
        case ValidationState.REDIRECT_TO_LOGIN_ONBOARDING_COMPLETE:
        case ValidationState.REDIRECT_TO_LOGIN_ONBOARDING_PENDING:
            return null;
    }
}

export function PublicValidationErrorPopup({
    error,
    origin,
}: {
    error: { reason: PublicValidationErrorReason } | null;
    origin: ValidationOrigin;
}) {
    const navigate = useNavigate();
    if (!error?.reason) {
        return <GenericErrorPopup onConfirm={() => navigate('/login')} />;
    }

    switch (error.reason) {
        case PublicValidationErrorReason.NOT_FOUND:
            return (
                <NotFoundPopup
                    onConfirm={() => navigate('/login')}
                    origin={origin}
                />
            );
    }
}

export function OnboardingCompletePopup({
    onConfirm,
    origin,
}: {
    onConfirm: () => void;
    origin: ValidationOrigin;
}) {
    return (
        <FeedbackPopup
            open={true}
            color="success"
            onConfirm={onConfirm}
            abortLabel={'OK'}
            title={
                <Localized
                    de={`Gültiges ${origin === ValidationOrigin.BADGE ? 'diesen Badge' : 'dieses Konto'}`}
                    fr={`${origin === ValidationOrigin.BADGE ? 'Badge' : 'Compte'} valable`}
                    it={`${origin === ValidationOrigin.BADGE ? 'Badge' : 'Conto'} valido`}
                    en={`Valid ${origin === ValidationOrigin.BADGE ? 'badge' : 'account'}`}
                />
            }
        >
            <p>
                <Localized
                    de={`${origin === ValidationOrigin.BADGE ? 'Dieser Badge' : 'Dieses Konto'} ist im Parkingabo-System korrekt konfiguriert.`}
                    fr={`Ce ${origin === ValidationOrigin.BADGE ? 'badge' : 'compte'} est correctement configuré dans le système Parkingabo.`}
                    it={`Questo ${origin === ValidationOrigin.BADGE ? 'badge' : 'conto'} è configurato correttamente nel sistema Parkingabo.`}
                    en={`This ${origin === ValidationOrigin.BADGE ? 'badge' : 'account'} is correctly configured in the Parkingabo system.`}
                />
            </p>
            <p>
                <Localized
                    de="Wenn Sie Ihre Konfiguration überprüfen/ändern oder Ihre Transaktionen einsehen möchten, melden Sie sich mit Ihren Zugangsdaten bei Ihrem Parkingabo-Konto an."
                    fr="Si vous souhaitez vérifier/modifier votre configuration ou consulter vos transactions, connectez-vous à votre compte Parkingabo avec vos identifiants."
                    it="Se vuole verificare/modificare la configurazione o consultare le transazioni, acceda al tuo conto Parkingabo con le sue credenziali."
                    en="If you want to check/change your configuration or consult your transactions, log in to your Parkingabo account with your credentials."
                />
            </p>
        </FeedbackPopup>
    );
}

export function OnboardingPendingPopup({
    onConfirm,
    origin,
}: {
    onConfirm: () => void;
    origin: ValidationOrigin;
}) {
    return (
        <FeedbackPopup
            open={true}
            color="warning"
            onConfirm={onConfirm}
            abortLabel={'OK'}
            title={
                <Localized
                    de="Konfiguration nicht vollständig"
                    fr="Configuration incomplet"
                    it="Configurazione non completa"
                    en="Configuration not complete"
                />
            }
        >
            <p>
                <Localized
                    de={`Um ${origin === ValidationOrigin.BADGE ? 'diesen Badge' : 'dieses Konto'} nutzen zu können, müssen Sie die Konfiguration abschließen, indem Sie sich mit Ihren Zugangsdaten bei Ihrem Parkingabo-Konto anmelden und den Anweisungen folgen.`}
                    fr={`Pour utiliser ce ${origin === ValidationOrigin.BADGE ? 'badge' : 'compte'}, vous devez compléter votre configuration en vous connectant à votre compte Parkingabo avec vos identifiants et en suivant les instructions.`}
                    it={`Per poter utilizzare questo ${origin === ValidationOrigin.BADGE ? 'badge' : 'conto'}, deve completare la configurazione accedendo al suo conto con le sue credenziali e seguendo le istruzioni.`}
                    en={`In order to use this ${origin === ValidationOrigin.BADGE ? 'badge' : 'account'}, you must complete the configuration by logging into your Parkingabo account using your credentials and following the instructions.`}
                />
            </p>
        </FeedbackPopup>
    );
}

export function ConfirmEmailPopup({
    emailToBeConfirmed,
    onConfirm,
    onAbort,
}: {
    emailToBeConfirmed: string;
    onConfirm: () => void;
    onAbort: () => void;
}) {
    return (
        <FeedbackPopup
            open={true}
            color="warning"
            onConfirm={onConfirm}
            confirmLabel={
                <Localized
                    de="ERNEUT SENDEN"
                    fr="RENVOYER"
                    it="INVIA DI NUOVO"
                    en="RESEND"
                />
            }
            onAbort={onAbort}
            abortLabel={
                <Localized
                    de="ERNEUT REGISTRIEREN"
                    fr="RÉPÉTER L'INSCRIPTION"
                    it="RIPETI ISCRIZIONE"
                    en="REPEAT REGISTRATION"
                />
            }
            title={
                <Localized
                    de="Unbestätigte E-Mail Adresse"
                    fr="Adresse e-mail non confirmée"
                    it="Indirizzo e-mail non confermato"
                    en="Unconfirmed e-mail address"
                />
            }
        >
            <Localized
                de={
                    <p>
                        Sie haben die E-Mail-Adresse <b>{emailToBeConfirmed}</b>{' '}
                        noch nicht bestätigt.
                    </p>
                }
                fr={
                    <p>
                        Vous n&#39;avez pas encore confirmé l&#39;adresse e-mail{' '}
                        <b>{emailToBeConfirmed}</b>.
                    </p>
                }
                it={
                    <p>
                        Non ha ancora confermato l&#39;indirizzo e-mail{' '}
                        <b>{emailToBeConfirmed}</b>.
                    </p>
                }
                en={
                    <p>
                        You have not yet confirmed the email address{' '}
                        <b>{emailToBeConfirmed}</b>.
                    </p>
                }
            />
            <p>
                <Localized
                    de="Sollten Sie die E-Mail-Mitteilung mit dem Bestätigungslink nicht erhalten haben, überprüfen Sie in Ihrem E-Mail-Programm den Spam-Ordner."
                    fr="Veuillez vérifier le dossier spam dans votre programme de messagerie si vous n'avez pas reçu le message e-mail avec le lien de confirmation."
                    it="Se non ha ricevuto il messagio e-mail con il link di conferma, verifichi per favore la cartella spam (posta indesiderata) nel suo programma di posta elettronica."
                    en="Please also check the spam folder in your email program, if you have not received the email message with the confirmation link."
                />
            </p>
            <p>
                <Localized
                    de="Sie können mit «ERNEUT SENDEN» einen neuen Bestätigungslink anfordern oder mit «ERNEUT REGISTRIEREN» die E-Mail-Adresse korrigieren."
                    fr="Vous pouvez demander un nouveau lien de confirmation avec «RENVOYER» ou corriger votre adresse e-mail avec «RÉPÉTER L'INSCRIPTION»."
                    it="Può richiedere un nuovo link di conferma cliccando su «INVIA DI NUOVO» oppure può correggere l'indirizzo e-mail con «RIPETI ISCRIZIONE»."
                    en="You can request a new confirmation link with «RESEND» or you can correct your e-mail address with «REPEAT REGISTRATION»."
                />
            </p>
        </FeedbackPopup>
    );
}

function AlreadyRegisteredError({
    onConfirm,
    origin,
}: {
    onConfirm: () => void;
    origin: ValidationOrigin;
}) {
    return (
        <FeedbackPopup
            open={true}
            color="error"
            onConfirm={onConfirm}
            confirmLabel={'OK'}
            title={
                <Localized
                    de="Bereits registriert"
                    fr="Déjà enregistré"
                    it="Già registrato"
                    en="Already registered"
                />
            }
        >
            <p>
                <Localized
                    de={`Sie sind bereits bei diesem Parkingabo Betreiber registriert und können sich daher nicht erneut mit diesem ${origin === ValidationOrigin.BADGE ? 'Badge' : 'Link'} registrieren.`}
                    fr={`Vous êtes déjà enregistré déjà inscrit auprès de cet opérateur du Parkingabo et ne pouvez donc pas vous enregistrer à nouveau avec ce ${origin === ValidationOrigin.BADGE ? 'badge' : 'lien'}.`}
                    it={`Sei già registrato con questo operatore Parkingabo e quindi non è possibile effettuare una nuova registrazione con questo ${origin === ValidationOrigin.BADGE ? 'badge' : 'collegamento'}.`}
                    en={`You are already registered with this Parkingabo provider and therefore cannot register again with this ${origin === ValidationOrigin.BADGE ? 'badge' : 'link'}.`}
                />
            </p>
        </FeedbackPopup>
    );
}

function NotFoundPopup({
    onConfirm,
    origin,
}: {
    onConfirm: () => void;
    origin: ValidationOrigin;
}): JSX.Element | null {
    switch (origin) {
        case ValidationOrigin.BADGE:
            return (
                <FeedbackPopup
                    open={true}
                    color="error"
                    onConfirm={onConfirm}
                    confirmLabel={'OK'}
                    title={
                        <Localized
                            de="Badge nicht gefunden"
                            fr="Badge introuvable"
                            it="Badge non trovato"
                            en="Badge not found"
                        />
                    }
                >
                    <p>
                        <Localized
                            de="Dieser Badge ist entweder im Parkingabo-System nicht konfiguriert oder kann nicht Online abgefragt werden."
                            fr="Ce badge n'est pas configuré dans le système Parkingabo ou ne peut pas être consulté en ligne."
                            it="Questo badge non è configurato nel sistema Parkingabo oppure non può essere consultato online."
                            en="This badge is either not configured in the Parkingabo system or cannot be accessed online."
                        />
                    </p>
                </FeedbackPopup>
            );
        case ValidationOrigin.CUSTOMER:
            return (
                <FeedbackPopup
                    open={true}
                    color="error"
                    onConfirm={onConfirm}
                    confirmLabel={'OK'}
                    title={
                        <Localized
                            de="Konto nicht gefunden"
                            fr="Compte introuvable"
                            it="Conto non trovato"
                            en="Account not found"
                        />
                    }
                >
                    <p>
                        <Localized
                            de="Dieses Konto ist entweder im Parkingabo-System nicht konfiguriert oder kann nicht Online abgefragt werden."
                            fr="Ce compte n'est pas configuré dans le système Parkingabo ou ne peut pas être consulté en ligne."
                            it="Questo conto non è configurato nel sistema Parkingabo oppure non può essere consultato online."
                            en="This badge is either not configured in the Parkingabo system or cannot be accessed online."
                        />
                    </p>
                </FeedbackPopup>
            );
        case ValidationOrigin.TENANT:
            return null;
    }
}

export function GenericErrorPopup({ onConfirm }: { onConfirm: () => void }) {
    return (
        <FeedbackPopup
            open={true}
            color="error"
            onConfirm={onConfirm}
            confirmLabel={'OK'}
            title={
                <Localized
                    de="Es ist ein Fehler aufgetreten."
                    fr="Une erreur est survenue."
                    it="Si è verificato un errore."
                    en="An error has occurred."
                />
            }
        >
            <p>
                <Localized
                    de="Bitte versuchen Sie es in ein paar Minuten erneut."
                    fr="Veuillez réessayer dans quelques minutes."
                    it="Voglia riprovare tra qualche minuto."
                    en="Please try again in a few minutes."
                />
            </p>
        </FeedbackPopup>
    );
}

export interface LinkLoginData {
    email: string;
    operatorName: string;
}

export function LinkLoginPopup({
    linkLoginData,
    onConfirm,
    onAbort,
    linkCustomerToLoginState,
}: {
    linkLoginData: LinkLoginData;
    onConfirm: () => void;
    onAbort: () => void;
    linkCustomerToLoginState: ServerRequestState<object, ValidationData>;
}) {
    const navigate = useNavigate();
    const [linkError, setLinkError] = useState(false);

    useServerSuccessEffect(linkCustomerToLoginState, () => navigate('/'));

    useServerErrorEffect(linkCustomerToLoginState, () => setLinkError(true));

    return (
        <>
            <FeedbackPopup
                open={true}
                color="warning"
                onConfirm={onConfirm}
                confirmLabel={
                    <Localized
                        de="Verknüpfen"
                        fr="Lier"
                        it="Collega"
                        en="Link"
                    />
                }
                onAbort={onAbort}
                abortLabel={
                    <Localized
                        de="Ignorieren"
                        fr="Ignorer"
                        it="Ignora"
                        en="Ignore"
                    />
                }
                title={
                    <Localized
                        de="Konto verknüpfen"
                        fr="Lier le compte"
                        it="Collegare conto"
                        en="Link account"
                    />
                }
            >
                <p>
                    <Localized
                        de="Sie sind bereits bei Parkingabo mit der E-Mail-Adresse "
                        fr="Vous êtes déjà enregistré dans Parkingabo avec l'adresse e-mail "
                        it="È già registrato in Parkingabo con l'indirizzo e-mail "
                        en="You are already registered in Parkingabo with the e-mail address "
                    />
                    <b>{linkLoginData.email}</b>
                    <Localized de=" registriert." fr="." it="." en="." />
                </p>
                <p>
                    <Localized
                        de="Möchten Sie diese E-Mail-Adresse für den Zugriff auf das neue Parkingabo-Konto bei "
                        fr="Souhaitez-vous utiliser cette adresse e-mail pour accéder au nouveau compte Parkingabo chez "
                        it="Vuole utilizzare questo indirizzo e-mail per accedere al nuovo contro Parkingabo presso "
                        en="Do you want to use this email address to access the new Parkingabo account at "
                    />
                    <b>{linkLoginData.operatorName}</b>
                    <Localized de=" verwenden?" fr="?" it="?" en="?" />
                </p>
            </FeedbackPopup>
            {linkError && <GenericErrorPopup onConfirm={() => navigate('/')} />}
        </>
    );
}

export function EmailNotValidPopup({ onConfirm }: { onConfirm: () => void }) {
    return (
        <FeedbackPopup
            open={true}
            color="error"
            onConfirm={onConfirm}
            confirmLabel={'OK'}
            title={
                <Localized
                    de="E-Mail Adresse nicht erlaubt"
                    fr="Adresse e-mail pas permise"
                    it="Indirizzo e-mail non consentito"
                    en="Email address not allowed"
                />
            }
        >
            <p>
                <Localized
                    de="Sie sind bereits bei Parkingabo registriert."
                    fr="Vous êtes déjà enregistré dans Parkingabo."
                    it="È già registrato in Parkingabo."
                    en="You are already registered in Parkingabo."
                />
            </p>
            <p>
                <Localized
                    de="Für das neue Konto kann diese E-Mail-Adresse aufgrund der vom Betreiber festgelegten Regeln nicht verwendet werden."
                    fr="Pour le nouveau compte, selon les règles définies par l'exploitant, cette adresse e-mail ne peut pas être utilisée."
                    it="Per il nuovo conto, sulla base delle regole definite dal gestore, non è possibile utilizzare questo indirizzo e-mail."
                    en="For the new account, based on the rules defined by the operator, it is not possible to use this e-mail address."
                />
            </p>
            <p>
                <Localized
                    de="Melden Sie sich vom bestehenden Konto ab und wiederholen Sie den Registrierungsvorgang; wählen Sie dabei eine andere E-Mail-Adresse für das neue Konto."
                    fr="Déconnectez-vous du compte existant et répétez la procédure d'enregistrement en choisissant une adresse e-mail différente pour le nouveau compte."
                    it="Voglia uscire dal conto esistente e ripetere la procedura di registrazione, scegliendo un indirizzo e-mail separato per il nuovo conto."
                    en="Log out from the existing account and repeat the registration procedure, choosing a different e-mail address for the new account."
                />
            </p>
        </FeedbackPopup>
    );
}
