import { createContext, useContext } from 'react';
import { useParamOrNull } from 'dg-web-shared/lib/ReactRouterHelpers.ts';
import { Navigate, Outlet } from 'react-router-dom';
import { ParkingaboAsyncLoadedSection } from './ParkingaboAsyncLoadedSection.tsx';
import { useServerFetch } from 'dg-web-shared/lib/hooks/ServerStateHooks.ts';

export const UnauthenticatedTenantContext = createContext<{
    tenantId: number | null;
    allowSignup: boolean | null;
}>({ tenantId: null, allowSignup: null });

export function UnauthenticatedTenantContextProvider() {
    const tenantId = Number(useParamOrNull('tenantId'));
    const [unauthenticatedTenantState] = useServerFetch<
        UnauthenticatedTenant,
        { tenantId: number }
    >(
        ({ tenantId }) => ({
            url: `/ui-api/parkingabo/unauthenticated-tenant/${tenantId}`,
        }),
        tenantId ? { tenantId } : null,
    );

    if (isNaN(tenantId)) {
        return <Navigate to={'/login'} />;
    }

    return (
        <ParkingaboAsyncLoadedSection
            state={unauthenticatedTenantState}
            render={UnauthenticatedTenant => (
                <UnauthenticatedTenantContext.Provider
                    value={{
                        tenantId: UnauthenticatedTenant.tenantId,
                        allowSignup: UnauthenticatedTenant.allowSignup,
                    }}
                >
                    <Outlet />
                </UnauthenticatedTenantContext.Provider>
            )}
        />
    );
}

export function useUnauthenticatedTenant(): UnauthenticatedTenant {
    const context = useContext(UnauthenticatedTenantContext);
    return {
        tenantId: context.tenantId!,
        allowSignup: context.allowSignup!,
    };
}

interface UnauthenticatedTenant {
    tenantId: number;
    allowSignup: boolean;
}
