import { useEffect } from 'react';
import {
    FetchInfo,
    RequestStatus,
    ServerFetchState,
    ServerRequestState,
    ServerWriteState,
    useServerFetch,
    useServerWrite,
    WriteInfo,
} from 'dg-web-shared/lib/hooks/ServerStateHooks';
import { ValidationData } from 'dg-web-shared/lib/forms/FormValidationHelpers';
import { useCustomerTenant } from '../components/CustomerTenantProvider.tsx';

function useRedirectOnError(
    state: ServerRequestState<unknown, unknown>,
    noUnauthRefresh: boolean,
) {
    useEffect(() => {
        if (
            !noUnauthRefresh &&
            state.status === RequestStatus.ERROR &&
            state.httpStatusCode === 401
        ) {
            location.href = '';
        }
    }, [state, noUnauthRefresh]);
}

type NoContext = Record<string, never>;

export function useParkingaboServerFetch<
    Data,
    Context extends Record<string, unknown> = NoContext,
    ErrorData = null,
>(
    requestInfoFactory: FetchInfo<Context>,
    context: Context | null,
): ServerFetchState<Data, ErrorData> {
    const {
        user: { tenantId },
    } = useCustomerTenant();
    const [state, refetchSameContext] = useServerFetch<
        Data,
        Context,
        ErrorData
    >(
        context => {
            const requestInfo = requestInfoFactory(context);
            return {
                ...requestInfo,
                url: `/ui-api/parkingabo/tenant/${context.tenantId}/${requestInfo.url}`,
            };
        },
        context ? { ...context, tenantId } : null,
    );
    useRedirectOnError(state, false);
    return [state, refetchSameContext];
}

export function useParkingaboServerWrite<
    Payload,
    Data,
    ErrorData = ValidationData,
>(
    requestInfoFactory: WriteInfo<Payload>,
    noUnauthRefresh?: boolean,
): ServerWriteState<Data, ErrorData, Payload> {
    const {
        user: { tenantId },
    } = useCustomerTenant();
    const [state, write, reset] = useServerWrite<Payload, Data, ErrorData>(
        payload => {
            const requestInfo = requestInfoFactory(payload);
            return {
                ...requestInfo,
                url: `/ui-api/parkingabo/tenant/${tenantId}/${requestInfo.url}`,
            };
        },
    );
    useRedirectOnError(state, noUnauthRefresh ?? false);
    return [state, write, reset];
}
