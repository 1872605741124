import { Box, Link, Stack, Typography } from '@mui/material';

import { useParkingaboAuthedPathGeneration } from '../RouteUtils';
import { Localized } from 'dg-web-shared/common/hooks/LanguageProvider';
import { AccountBalance, DriveEta, LocalParking } from '@mui/icons-material';
import { VehicleQrCodeDrawer } from '../../components/VehicleQrCodeDrawer';
import { ParkingaboProductState } from '../../shared/ParkingaboProductModels';
import { ParkingaboLogoHeaderCompact } from '../../components/layout/ParkingaboLogoHeader';
import { Outlet, useNavigate } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import {
    AliasInvalidReason,
    CustomerTenantState,
    ParkingaboUser,
    Tenant,
} from '../../shared/ParkingaboModels';
import { currencyCentsToLocalPrice } from 'dg-web-shared/lib/NumberFormatter';
import { FeedbackPopup } from '../../components/FeedbackPopup';
import { useState } from 'react';
import { useCustomerTenant } from '../../components/CustomerTenantProvider.tsx';
import { TenantAllowedBarrierGateVehicleIdentification } from 'dg-web-shared/model/TenantEnums.ts';
import { ParkingaboVehicle } from 'dg-web-shared/common/models/Vehicle.tsx';
import { isLicensePlateExpected } from 'product-shared/tenant/TenantVehicleIdentificationUtils.ts';
import { envIsProduction, envName } from 'dg-web-shared/lib/Environment.ts';
import { ParkingaboScrollableLayout } from '../../components/layout/ParkingaboLayout.tsx';
import { ParkingaboCardButton } from '../../components/layout/ParkingaboCardButton.tsx';
import { ParkingaboLink } from '../../components/ParkinaboLink.tsx';

export function HomeRoute() {
    const generateAuthedParkingaboPath = useParkingaboAuthedPathGeneration();
    const { products, user, vehicles, tenant, linkedUserCount } =
        useCustomerTenant();
    const navigate = useNavigate();
    const [openOverduePaymentDialog, setOpenOverduePaymentDialog] =
        useState(false);

    return (
        <ParkingaboScrollableLayout>
            <ParkingaboLogoHeaderCompact
                settingsLinkTo={generateAuthedParkingaboPath('settings')}
            />
            <AccountStateAlerts
                tenant={tenant}
                user={user}
                vehicles={vehicles.data}
                setOpenOverduePaymentDialog={() =>
                    setOpenOverduePaymentDialog(true)
                }
            />
            <Box sx={{ padding: theme => theme.spacing(4) }}>
                <Typography
                    variant="h1"
                    color="inherit"
                    sx={theme => ({
                        paddingTop: theme.spacing(1),
                        textAlign: 'center',
                        paddingBottom: theme.spacing(
                            linkedUserCount > 1 ? 2 : 5,
                        ),
                    })}
                >
                    {tenant.tenantName}
                </Typography>
                {linkedUserCount > 1 && (
                    <Box
                        sx={theme => ({
                            textAlign: 'center',
                            paddingBottom: theme.spacing(3),
                        })}
                    >
                        <ParkingaboLink to="/">
                            <Localized
                                de="Konto wechseln"
                                fr="Changer de compte"
                                it="Cambia account"
                                en="Switch account"
                            />
                        </ParkingaboLink>
                    </Box>
                )}
                <Stack spacing={2}>
                    <ParkingaboCardButton
                        to={'products'}
                        label={
                            <Localized
                                de="Produkte"
                                fr="Produits"
                                it="Prodotti"
                                en="Products"
                            />
                        }
                        Icon={LocalParking}
                        counter={
                            products.filter(
                                product =>
                                    product.state ===
                                    ParkingaboProductState.ACTIVE,
                            ).length
                        }
                    />
                    <ParkingaboCardButton
                        to={'account-statement'}
                        label={
                            <Localized
                                de="Kontoauszug"
                                fr="Relevé de compte"
                                it="Estratto conto"
                                en="Account statement"
                            />
                        }
                        Icon={AccountBalance}
                        counter={0}
                    />
                    <ParkingaboCardButton
                        to={'vehicles'}
                        label={
                            <Localized
                                de="Fahrzeuge"
                                fr="Véhicules"
                                it="Veicoli"
                                en="Vehicles"
                            />
                        }
                        Icon={DriveEta}
                        counter={vehicles.data.length}
                    />
                </Stack>
                {!envIsProduction() && (
                    <Typography
                        sx={{
                            textAlign: 'center',
                            marginTop: 3,
                        }}
                        fontWeight="bold"
                    >
                        {envName()}
                    </Typography>
                )}
                <FeedbackPopup
                    open={openOverduePaymentDialog}
                    title={
                        <Localized
                            de="Zahlungsverzug"
                            fr="Paiements en souffrance"
                            it="Pagamenti in arretrato"
                            en="Overdue payments"
                        />
                    }
                    onConfirm={() =>
                        navigate(
                            generateAuthedParkingaboPath('payment/register'),
                        )
                    }
                    onAbort={() => setOpenOverduePaymentDialog(false)}
                    confirmLabel={
                        <Localized
                            de="Bezahlen"
                            fr="Payer"
                            it="Paga"
                            en="Pay"
                        />
                    }
                    abortLabel={
                        <Localized
                            de="Abbrechen"
                            fr="Annuler"
                            it="Annulla"
                            en="Cancel"
                        />
                    }
                >
                    <>
                        <p>
                            <Localized
                                de="Eine oder mehrere automatische Zahlungen am Ende des Monats konnten nicht durchgeführt werden."
                                fr="Un ou plusieurs paiements automatiques à la fin du mois n'ont pas pu être effectués."
                                it="Uno o più pagamenti automatici a fine mese non hanno potuto essere eseguiti."
                                en="One or more automatic payments at the end of the month could not be made."
                            />
                        </p>
                        <p>
                            <Localized
                                de="Wenn Sie auf «Bezahlen» klicken, können Sie den gesamten negativen Saldo Ihres Kontos ausgleichen (einschließlich allfälliger zwischenzeitlicher neuer Belastungen)."
                                fr="En cliquant sur «Payer», vous pouvez régler la totalité du solde négatif de votre compte (y compris les nouveaux débits intervenus entre-temps)."
                                it="Cliccando su «Paga» potrà saldare l’intero saldo negativo del suo conto (incl. nuovi addebiti sopraggiunti nel frattempo)."
                                en="By clicking on «Pay» you can settle the entire negative balance of your account (including any new debits in the meantime)."
                            />
                        </p>
                    </>
                </FeedbackPopup>
                <Outlet />
            </Box>
            {tenant.allowedBarrierGateVehicleIdentification ===
                TenantAllowedBarrierGateVehicleIdentification.LICENSE_PLATE_QR && (
                <VehicleQrCodeDrawer vehicles={vehicles.data} />
            )}
        </ParkingaboScrollableLayout>
    );
}

function AccountStateAlerts({
    tenant,
    user,
    vehicles,
    setOpenOverduePaymentDialog,
}: {
    tenant: Tenant;
    user: ParkingaboUser;
    vehicles: ParkingaboVehicle[];
    setOpenOverduePaymentDialog: () => void;
}) {
    const vehiclesWithoutLp = vehicles.filter(v => !v.licensePlateNr).length;
    return (
        <>
            {user.customerState === CustomerTenantState.LOCKED && (
                <Alert
                    severity="error"
                    sx={{ backgroundColor: 'rgb(255, 195, 195)' }}
                >
                    <Typography>
                        <Localized
                            de="Konto gesperrt"
                            fr="Compte bloqué"
                            it="Conto bloccato"
                            en="Account locked"
                        />
                    </Typography>
                </Alert>
            )}
            {user.overduePaymentsAmountRappen !== null ? (
                <Alert
                    severity="warning"
                    sx={{ backgroundColor: 'rgb(255, 235, 195)' }}
                >
                    <Typography>
                        <Localized
                            de={`Zahlungsverzug: ${currencyCentsToLocalPrice(
                                'de',
                                user.overduePaymentsAmountRappen,
                            )} `}
                            fr={`Montant en retard: ${currencyCentsToLocalPrice(
                                'fr',
                                user.overduePaymentsAmountRappen,
                            )} `}
                            it={`Importo in arretrato: ${currencyCentsToLocalPrice(
                                'it',
                                user.overduePaymentsAmountRappen,
                            )} `}
                            en={`Overdue amount: ${currencyCentsToLocalPrice(
                                'en',
                                user.overduePaymentsAmountRappen,
                            )} `}
                        />
                        <Link
                            onClick={() => setOpenOverduePaymentDialog()}
                            underline="always"
                            sx={{ cursor: 'pointer' }}
                        >
                            <Localized
                                de="(mehr)"
                                fr="(plus)"
                                it="(di più)"
                                en="(more)"
                            />
                        </Link>
                    </Typography>
                </Alert>
            ) : (
                user.aliasInvalidReason && (
                    <Alert
                        severity="warning"
                        sx={{ backgroundColor: 'rgb(255, 235, 195)' }}
                    >
                        <Typography>
                            <AliasInvalidAlertMessage
                                aliasInvalidReason={user.aliasInvalidReason}
                            />
                        </Typography>
                    </Alert>
                )
            )}
            {isLicensePlateExpected(
                tenant.allowedEnforcedVehicleIdentification,
                tenant.allowedBarrierGateVehicleIdentification,
            ) &&
                vehiclesWithoutLp !== 0 && (
                    <Alert
                        severity="warning"
                        sx={{ backgroundColor: 'rgb(255, 235, 195)' }}
                    >
                        <Typography>
                            {vehiclesWithoutLp === 1 ? (
                                <Localized
                                    de="1 Fahrzeug ohne Kennzeichen"
                                    fr="1 véhicule sans plaque"
                                    it="1 veicolo senza targa"
                                    en="1 vehicle without licence plate"
                                />
                            ) : (
                                <Localized
                                    de={`${vehiclesWithoutLp} Fahrzeuge ohne Kennzeichen`}
                                    fr={`${vehiclesWithoutLp} véhicules sans plaque`}
                                    it={`${vehiclesWithoutLp} veicoli senza targa`}
                                    en={`${vehiclesWithoutLp} vehicles without licence plate`}
                                />
                            )}
                        </Typography>
                    </Alert>
                )}
        </>
    );
}

function AliasInvalidAlertMessage({
    aliasInvalidReason,
}: {
    aliasInvalidReason: AliasInvalidReason;
}) {
    switch (aliasInvalidReason) {
        case AliasInvalidReason.EXPIRED:
            return (
                <Localized
                    de="Zahlungsmittel abgelaufen"
                    fr="Moyen de paiement échu"
                    it="Mezzo di pagamento scaduto"
                    en="Payment mean expired"
                />
            );
        case AliasInvalidReason.LAST_DIRECT_FAILED:
            return (
                <Localized
                    de="Zahlungsmittel abgelehnt"
                    fr="Moyen de paiement rejeté"
                    it="Mezzo di pagamento rifiutato"
                    en="Payment mean rejected"
                />
            );
        case AliasInvalidReason.ONBOARDING_REQUIRED:
        case AliasInvalidReason.NOT_FOUND:
            return (
                <Localized
                    de="Zahlungsmittel fehlt"
                    fr="Moyen de paiement manquant"
                    it="Mezzo di pagamento mancante"
                    en="Payment mean missing"
                />
            );
    }
}
