import { useEffect, useState } from 'react';
import { Outlet, useMatch, useNavigate } from 'react-router-dom';
import { useParams } from 'react-router';
import {
    OnboardingStepType,
    useOnboarding,
} from '../routes/authorized/onboarding/OnboardingConfig';
import {
    LoadingSpinnerPresets,
    PresetLoadingSpinner,
} from 'dg-web-shared/common/components/material-ui/PresetLoadingSpinner';
import { useParkingaboAuthedPathGeneration } from '../routes/RouteUtils';

import { useDocumentVisibilityEffect } from 'dg-web-shared/common/hooks/useDocumentVisibility';
import { useCustomerTenant } from './CustomerTenantProvider.tsx';
import { AppMode } from 'dg-web-shared/model/TenantEnums.ts';

export function OnboardingRequiredChecker() {
    const navigate = useNavigate();
    const { tenant, refetchUser } = useCustomerTenant();
    const generateAuthedParkingaboPath = useParkingaboAuthedPathGeneration();
    const { config } = useOnboarding();
    const firstIncompleteOnboardingStep = config.steps.find(
        step => !step.completed,
    );
    const isConfigLoaded = !!config.steps.find(
        step => step.completed !== undefined,
    );
    const { tenantId } = useParams<{ tenantId?: string }>();
    const [routeChecked, setRouteChecked] = useState(false);
    const onboardingSuccess =
        useMatch(generateAuthedParkingaboPath('onboarding/success')) !== null;

    useDocumentVisibilityEffect(visibilityState => {
        if (visibilityState === 'visible') {
            refetchUser();
        }
    });

    useEffect(() => {
        if (tenantId && isConfigLoaded) {
            if (!onboardingSuccess) {
                if (firstIncompleteOnboardingStep) {
                    navigate(
                        getRedirectPathForAppModeAndOnboardingStep(
                            generateAuthedParkingaboPath,
                            tenant?.appMode,
                            firstIncompleteOnboardingStep.type,
                        ),
                    );
                }
            }
            setRouteChecked(true);
        }
    }, [
        config,
        isConfigLoaded,
        firstIncompleteOnboardingStep,
        tenantId,
        onboardingSuccess,
    ]);

    if (!routeChecked) {
        return (
            <PresetLoadingSpinner
                preset={LoadingSpinnerPresets.FillAllSpaceAndCenter}
            />
        );
    } else {
        return <Outlet />;
    }
}

function getRedirectPathForAppModeAndOnboardingStep(
    generateAuthedParkingaboPath: (path: string) => string,
    appMode: AppMode,
    step: OnboardingStepType,
): string {
    switch (appMode) {
        case AppMode.NO_SIGNUP:
            return getNoSignupRedirectsForOnboardingStep(
                step,
                generateAuthedParkingaboPath,
            );
        case AppMode.SIGNUP_BY_EMAIL_DOMAIN:
            return generateAuthedParkingaboPath(
                getSignupByEmailDomainPathForOnboardingStep(step),
            );
        case AppMode.NONE:
            return '/not-found';
    }
}

function getNoSignupRedirectsForOnboardingStep(
    step: OnboardingStepType,
    generateAuthedParkingaboPath: (path: string) => string,
): string {
    switch (step) {
        case OnboardingStepType.PAYMENT:
            return generateAuthedParkingaboPath(
                'onboarding-with-badge/payment',
            );
        case OnboardingStepType.USER_DATA:
        case OnboardingStepType.VEHICLE:
            return '/not-found';
    }
}

function getSignupByEmailDomainPathForOnboardingStep(
    step: OnboardingStepType,
): string {
    switch (step) {
        case OnboardingStepType.USER_DATA:
            return 'onboarding/user-data';
        case OnboardingStepType.VEHICLE:
            return 'onboarding/vehicle';
        case OnboardingStepType.PAYMENT:
            return 'onboarding/payment';
    }
}
