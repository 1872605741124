import { Box, TextField } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { CancelSave } from '../../../components/layout/CancelSave';
import { ReactHookFormTextField } from 'dg-web-shared/common/components/material-ui/react-hook-form-fields/ReactHookFormTextField';
import {
    Localized,
    useLanguage,
} from 'dg-web-shared/common/hooks/LanguageProvider';
import { useEasyForm } from 'dg-web-shared/common/utils/FormHooksUtils';
import { useNavigateOnSuccess } from 'dg-web-shared/lib/hooks/ServerStateHooks';
import { useParkingaboServerWrite } from '../../../api/ParkingaboApi';
import { ParkingaboLayoutWithHeader } from '../../../components/layout/ParkingaboLayoutWithHeader';
import {
    computePasswordStrength,
    PasswordStrengthIndicator,
} from '../../../components/PasswordStrengthIndicator';
import { useParkingaboAuthedPathGeneration } from '../../RouteUtils';
import { useNavigate } from 'react-router-dom';
import { ParkingaboRoutedModal } from '../../../components/layout/ParkingaboRoutedModal';
import { useState } from 'react';
import { GenericFormSubmitError } from '../../../shared/GenericFormSubmitError.tsx';

enum Fields {
    OldPassword = 'oldPassword',
    NewPassword = 'newPassword',
}

interface ChangePasswordPaylaod {
    [Fields.OldPassword]: string;
    [Fields.NewPassword]: string;
}

export function EditPasswordRoute() {
    const generateAuthedParkingaboPath = useParkingaboAuthedPathGeneration();

    return (
        <ParkingaboRoutedModal
            backUrl={generateAuthedParkingaboPath('settings/user')}
            open={true}
            render={() => <EditPassword />}
        />
    );
}

function EditPassword() {
    const { language } = useLanguage();
    const navigate = useNavigate();
    const generateAuthedParkingaboPath = useParkingaboAuthedPathGeneration();
    const theme = useTheme();
    const [strength, setStrength] = useState({
        valid: false,
        strength: 0,
    });
    const [repeatPassword, setRepeatPassword] = useState('');
    const [changeState, sendChange] = useParkingaboServerWrite<
        ChangePasswordPaylaod,
        void
    >(
        () => ({
            url: 'user/change-password',
        }),
        true,
    );

    useNavigateOnSuccess(
        changeState,
        generateAuthedParkingaboPath('settings/user'),
    );

    const {
        formInfo: { control, handleSubmit, watch, reset },
        formState: { isDirty },
        genericSubmitError,
    } = useEasyForm(undefined, changeState, language, {
        defaultValues: {
            [Fields.OldPassword]: '',
            [Fields.NewPassword]: '',
        },
    });

    const passwordNotMatching =
        repeatPassword.length > 0 &&
        repeatPassword !== watch(Fields.NewPassword);
    const noRepeat = repeatPassword.length === 0;
    const canSend =
        strength.valid &&
        !passwordNotMatching &&
        !noRepeat &&
        isDirty &&
        watch(Fields.OldPassword).length > 0;

    return (
        <form
            style={{ height: '100%' }}
            onSubmit={handleSubmit((c: ChangePasswordPaylaod) => sendChange(c))}
        >
            <ParkingaboLayoutWithHeader
                backTo={generateAuthedParkingaboPath('settings/user')}
                title={
                    <Localized
                        de="Benutzer"
                        fr="Utilisateur"
                        it="Utente"
                        en="User"
                    />
                }
            >
                <Box>
                    <ReactHookFormTextField
                        style={{
                            marginBottom: theme.spacing(3),
                        }}
                        required={true}
                        control={control}
                        name={Fields.OldPassword}
                        label={
                            <Localized
                                de="Altes Passwort"
                                fr="Ancien mot de passe"
                                it="Vecchia password"
                                en="Old password"
                            />
                        }
                        type="password"
                        autoComplete="current-password"
                    />
                    <ReactHookFormTextField
                        style={{
                            marginBottom: theme.spacing(3),
                        }}
                        required={true}
                        control={control}
                        name={Fields.NewPassword}
                        onChange={e =>
                            setStrength(computePasswordStrength(e.target.value))
                        }
                        label={
                            <Localized
                                de="Neues Passwort"
                                fr="Nouveau mot de passe"
                                it="Nuova password"
                                en="New password"
                            />
                        }
                        type="password"
                        autoComplete="new-password"
                    />
                    <PasswordStrengthIndicator
                        passwordStrength={strength.strength}
                    />
                    <TextField
                        style={{
                            marginBottom: theme.spacing(3),
                            marginTop: theme.spacing(3),
                        }}
                        required={true}
                        value={repeatPassword}
                        onChange={e => setRepeatPassword(e.target.value)}
                        name={Fields.OldPassword}
                        error={passwordNotMatching}
                        helperText={
                            passwordNotMatching ? (
                                <Localized
                                    de="Die zwei Passwörter stimmen nicht überein"
                                    fr="Les deux mots de passe ne correspondent pas"
                                    it="Le due password non corrispondono"
                                    en="The two passwords do not match"
                                />
                            ) : undefined
                        }
                        label={
                            <Localized
                                de="Neues Passwort bestätigen"
                                fr="Confirmer le nouveau mot de passe"
                                it="Conferma nuova password"
                                en="Repeat new password"
                            />
                        }
                        type="password"
                        autoComplete="new-password"
                    />
                    <GenericFormSubmitError
                        error={genericSubmitError}
                        submitState={changeState}
                    />
                </Box>
                <CancelSave
                    saveDisabled={!canSend}
                    requestState={changeState}
                    onCancel={() => {
                        reset();
                        navigate(-1);
                    }}
                />
            </ParkingaboLayoutWithHeader>
        </form>
    );
}
