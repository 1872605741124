import { BaseModal } from './EditProfileRoute';
import { UserDataForm } from '../../../components/forms/UserDataForm';
import { useCustomerTenant } from '../../../components/CustomerTenantProvider.tsx';

export function EditNameRoute() {
    const { user, refetchUser } = useCustomerTenant();
    return (
        <BaseModal
            open={true}
            user={user}
            refetchUser={refetchUser}
            render={control => <UserDataForm.NameField control={control} />}
        />
    );
}
