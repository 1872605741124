import { useProductAddContext } from './ProductsAddOutlet';
import { ProductsConfigurationReservation } from './ProductsConfigurationReservation';
import { ProductsConfigurationSubscription } from './ProductsConfigurationSubscription';
import { ParkingaboProductType } from '../../../shared/ParkingaboProductModels';
import { exhaustiveGuard } from 'dg-web-shared/lib/ExhaustiveGuard';
import { ProductsConfigurationGuestReservation } from './ProductsConfigurationGuestReservation';
import { ParkingaboOverlineList } from '../../../components/layout/ParkingaboOverlineList';
import { Localized } from 'dg-web-shared/common/hooks/LanguageProvider';
import { Stack } from '@mui/material';
import { VehicleLicensePlateType } from 'dg-web-shared/common/models/Vehicle';
import DriveEta from '@mui/icons-material/DriveEta';
import TwoWheeler from '@mui/icons-material/TwoWheeler';
import { useCustomerTenant } from '../../../components/CustomerTenantProvider.tsx';

export function ProductsConfigurationOutlet() {
    const { refetchProducts } = useCustomerTenant();
    const { zones, productSelectionState, disableProductChange } =
        useProductAddContext();
    if (productSelectionState.product === null) {
        return null;
    }
    if (
        productSelectionState.product?.type ===
        ParkingaboProductType.RESERVATION
    ) {
        return (
            <ProductsConfigurationReservation
                product={productSelectionState.product}
                zones={zones}
                noProductChange={disableProductChange}
                refetchProducts={refetchProducts}
            />
        );
    }

    if (
        productSelectionState.product?.type ===
        ParkingaboProductType.SUBSCRIPTION
    ) {
        return (
            <ProductsConfigurationSubscription
                product={productSelectionState.product}
                zones={zones}
                noProductChange={disableProductChange}
                refetchProducts={refetchProducts}
            />
        );
    }

    if (
        productSelectionState.product?.type ===
        ParkingaboProductType.GUEST_RESERVATION
    ) {
        return (
            <ProductsConfigurationGuestReservation
                product={productSelectionState.product}
                zones={zones}
                noProductChange={disableProductChange}
                refetchProducts={refetchProducts}
            />
        );
    }
    exhaustiveGuard(productSelectionState.product);
}

export function VehicleType({
    vehicleType,
}: {
    vehicleType: VehicleLicensePlateType;
}) {
    return (
        <ParkingaboOverlineList.Item
            label={
                <Localized
                    de="Fahrzeugtyp"
                    fr="Type de véhicule"
                    it="Tipo di veicolo"
                    en="Vehicle type"
                />
            }
        >
            <Stack direction={'row'} spacing={2} justifyItems={'flex-start'}>
                {vehicleType === VehicleLicensePlateType.CAR ? (
                    <>
                        <div>
                            <Localized
                                de="Auto"
                                fr="Voiture"
                                it="Automobile"
                                en="Car"
                            />
                        </div>
                        <DriveEta fontSize="small" />
                    </>
                ) : (
                    <>
                        <div>
                            <Localized
                                de="Motorrad"
                                fr="Moto"
                                it="Motocicletta"
                                en="Motorcycle"
                            />
                        </div>
                        <TwoWheeler fontSize="small" />
                    </>
                )}
            </Stack>
        </ParkingaboOverlineList.Item>
    );
}
