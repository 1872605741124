import { Box } from '@mui/material';

import { languageTranslations } from 'dg-web-shared/common/components/material-ui/react-hook-form-fields/CommonOptions';
import { Localized } from 'dg-web-shared/common/hooks/LanguageProvider';
import { CustomerTenantLegalForm } from 'dg-web-shared/common/models/Users';
import {
    RequestMethod,
    ServerRequestState,
    useNavigateOnSuccess,
    useServerSuccessEffect,
} from 'dg-web-shared/lib/hooks/ServerStateHooks';
import { useParkingaboServerWrite } from '../../../api/ParkingaboApi';
import { UserDataForm } from '../../../components/forms/UserDataForm';
import { ParkingaboLayoutWithHeader } from '../../../components/layout/ParkingaboLayoutWithHeader';
import { ParkingaboRoutedModal } from '../../../components/layout/ParkingaboRoutedModal';
import { ParkingaboMenuListItem } from '../../../components/ParkingaboMenuListItem';
import { ParkingaboUser } from '../../../shared/ParkingaboModels';
import { useParkingaboAuthedPathGeneration } from '../../RouteUtils';
import { ValidationData } from 'dg-web-shared/lib/forms/FormValidationHelpers';
import { Outlet, useNavigate } from 'react-router-dom';
import { Control } from 'react-hook-form';
import { Lock } from '@mui/icons-material';
import { useCustomerTenant } from '../../../components/CustomerTenantProvider.tsx';
import { AppMode } from 'dg-web-shared/model/TenantEnums.ts';
import { FeedbackPopup } from '../../../components/FeedbackPopup.tsx';
import { Dispatch, SetStateAction, useState } from 'react';
import OnboardingPayload = UserDataForm.UserData;

export function useUserUpdate(refetchUser: () => void) {
    const [state, submit] = useParkingaboServerWrite<
        UserDataForm.UserData,
        never
    >(() => ({
        url: 'user/self',
        method: RequestMethod.PUT,
    }));

    useServerSuccessEffect(state, refetchUser);
    return { state, submit };
}

export function BaseModal({
    open,
    refetchUser,
    render,
    noCancelSaveButton,
    user,
}: {
    open: boolean;
    user: ParkingaboUser;
    refetchUser: () => void;
    noCancelSaveButton?: boolean;
    render: (
        control: Control<OnboardingPayload>,
        requestState: ServerRequestState<never, ValidationData>,
        submit: () => void,
    ) => JSX.Element;
}) {
    const navigate = useNavigate();
    const { state, submit } = useUserUpdate(refetchUser);
    const generateAuthedParkingaboPath = useParkingaboAuthedPathGeneration();
    const profileSettingsPath = generateAuthedParkingaboPath('settings/user');
    useNavigateOnSuccess(state, profileSettingsPath);

    return (
        <ParkingaboRoutedModal
            backUrl={profileSettingsPath}
            open={open}
            render={() => (
                <ParkingaboLayoutWithHeader
                    backTo={profileSettingsPath}
                    title={
                        <Localized
                            de="Benutzer"
                            fr="Utilisateur"
                            it="Utente"
                            en="User"
                        />
                    }
                >
                    <UserDataForm.UserBaseForm
                        requestState={state}
                        submit={submit}
                        initialValues={user}
                        render={render}
                        noCancelSaveButton={noCancelSaveButton}
                        onCancel={() => {
                            navigate(-1);
                        }}
                    />
                </ParkingaboLayoutWithHeader>
            )}
        />
    );
}

export function EditProfileRoute() {
    const { user } = useCustomerTenant();
    const generateAuthedParkingaboPath = useParkingaboAuthedPathGeneration();
    const { tenant } = useCustomerTenant();
    const isCompany = user.legalForm === CustomerTenantLegalForm.COMPANY;
    const nameChangeable =
        tenant.appMode == AppMode.SIGNUP_BY_EMAIL_DOMAIN || isCompany;
    const [nameChangeInfoOpen, setNameChangeInfoOpen] = useState(false);
    return (
        <ParkingaboLayoutWithHeader
            backTo={generateAuthedParkingaboPath('settings')}
            noGutter
            title={
                <Localized
                    de="Benutzer"
                    fr="Utilisateur"
                    it="Utente"
                    en="User"
                />
            }
        >
            <Outlet />
            <Box>
                {isCompany && (
                    <ParkingaboMenuListItem
                        supText={
                            <Localized
                                de="Firmenname"
                                fr="Nom entreprise"
                                it="Nome società"
                                en="Company name"
                            />
                        }
                        text={`${user.company1 || ''}${user.company2 ? ' / ' + user.company2 : ''}`}
                        icons={[<Lock key={0} />]}
                        hideChevron
                        onClick={() => setNameChangeInfoOpen(true)}
                    />
                )}
                <ParkingaboMenuListItem
                    to={
                        nameChangeable
                            ? generateAuthedParkingaboPath('settings/user/name')
                            : undefined
                    }
                    supText={
                        isCompany ? (
                            <Localized
                                de="Kontaktperson"
                                fr="Personne de contact"
                                it="Persona di contatto"
                                en="Contact person"
                            />
                        ) : (
                            <Localized
                                de="Vorname / Nachname"
                                fr="Prénom / nom"
                                it="Nome / cognome"
                                en="Firstname / lastname"
                            />
                        )
                    }
                    text={`${user.firstName || ''} ${user.lastName || ''}`.trim()}
                    icons={nameChangeable ? undefined : [<Lock key={0} />]}
                    hideChevron={!nameChangeable}
                    onClick={
                        nameChangeable
                            ? undefined
                            : () => setNameChangeInfoOpen(true)
                    }
                />
                <NameChangeInfo
                    nameChangeInfoOpen={nameChangeInfoOpen}
                    setNameChangeInfoOpen={setNameChangeInfoOpen}
                />
                <ParkingaboMenuListItem
                    to={generateAuthedParkingaboPath('settings/user/language')}
                    supText={
                        <Localized
                            de="Korrespondenzsprache"
                            fr="Langue de correspondance"
                            it="Lingua di corrispondenza"
                            en="Correspondence language"
                        />
                    }
                    text={languageTranslations[user.language]}
                />
                <ParkingaboMenuListItem
                    to={generateAuthedParkingaboPath('settings/user/email')}
                    supText={
                        <Localized
                            de="E-Mail"
                            fr="E-mail"
                            it="E-mail"
                            en="Email"
                        />
                    }
                    text={
                        user.requestedEmail !== null ? (
                            <>
                                {user.requestedEmail}
                                <Localized
                                    de=" - nicht bestätigt"
                                    fr=" - pas confirmé"
                                    it=" - non confermato"
                                    en=" - not confirmed"
                                />
                            </>
                        ) : (
                            user.email
                        )
                    }
                />
                <ParkingaboMenuListItem
                    to={generateAuthedParkingaboPath('settings/user/password')}
                    supText={
                        <Localized
                            de="Passwort"
                            fr="Mot de passe"
                            it="Password"
                            en="Password"
                        />
                    }
                    text="********"
                />
            </Box>
        </ParkingaboLayoutWithHeader>
    );
}

function NameChangeInfo({
    nameChangeInfoOpen,
    setNameChangeInfoOpen,
}: {
    nameChangeInfoOpen: boolean;
    setNameChangeInfoOpen: Dispatch<SetStateAction<boolean>>;
}) {
    return (
        <FeedbackPopup
            open={nameChangeInfoOpen}
            color="info"
            title={
                <Localized
                    de="Änderung nicht möglich"
                    fr="Changement pas possible"
                    it="Modifica non possibile"
                    en="No changes possible"
                />
            }
            abortLabel={'OK'}
            onAbort={() => setNameChangeInfoOpen(false)}
        >
            <Localized
                de="Diese Daten können nur vom Betreiber geändert werden."
                fr="Ces données ne peuvent être modifiées que par l’exploitant."
                it="Questi dati possono essere modificati solo dal gestore."
                en="This data can only be changed by the operator."
            />
        </FeedbackPopup>
    );
}
