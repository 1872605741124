import { Delete, Error, Sync } from '@mui/icons-material';
import { Box } from '@mui/material';

import { useState } from 'react';
import { Localized } from 'dg-web-shared/common/hooks/LanguageProvider';
import {
    RequestStatus,
    useServerSuccessEffect,
} from 'dg-web-shared/lib/hooks/ServerStateHooks';
import {
    useParkingaboServerFetch,
    useParkingaboServerWrite,
} from '../../../api/ParkingaboApi';
import { BlockingLoadingModal } from '../../../components/BlockingLoadingModal';
import {
    PaymentMethodForm,
    PaymentMethodType,
} from '../../../components/forms/PaymentMethodForm';
import { ParkingaboLayoutWithHeader } from '../../../components/layout/ParkingaboLayoutWithHeader';
import { useParkingaboAuthedPathGeneration } from '../../RouteUtils';
import { FeedbackPopup } from '../../../components/FeedbackPopup';
import { ParkingaboMenuListItem } from '../../../components/ParkingaboMenuListItem';
import { PaymentIcon } from '../../../components/PaymentIcon';
import { Outlet } from 'react-router-dom';
import {
    CreditCardAliasFound,
    PaymentCardType,
    paymentMethodTranslations,
    SavedCreditCardAliasResponseTag,
} from 'dg-web-shared/model/PaymentAlias';
import { AliasInvalidReason } from '../../../shared/ParkingaboModels';
import { ParkingaboAsyncLoadedSection } from '../../../components/layout/ParkingaboAsyncLoadedSection';
import { useCustomerTenant } from '../../../components/CustomerTenantProvider.tsx';

export function PaymentSettingsRoute() {
    const { user, refetchUser } = useCustomerTenant();
    const generateAuthedParkingaboPath = useParkingaboAuthedPathGeneration();
    const [confirmDeletionOpen, setConfirmDeletionOpen] = useState(false);
    const [deleteState, deletePaymentMethod] = useParkingaboServerWrite<
        never,
        never
    >(() => ({
        url: `user/self/payment/remove-alias`,
    }));

    useServerSuccessEffect(deleteState, refetchUser);

    return (
        <ParkingaboLayoutWithHeader
            noGutter
            backTo={generateAuthedParkingaboPath('settings')}
            title={
                <Localized
                    de="Zahlungsmittel"
                    fr="Moyen de paiement"
                    it="Mezzo di pagamento"
                    en="Payment mean"
                />
            }
        >
            {user.alias.tag !== SavedCreditCardAliasResponseTag.FOUND ? (
                <PaymentMethodForm
                    paymentMethodType={PaymentMethodType.REGISTER}
                />
            ) : (
                <PaymentAliasItems
                    alias={user.alias}
                    aliasInvalidReason={user.aliasInvalidReason}
                    onDelete={() => setConfirmDeletionOpen(true)}
                />
            )}

            <Outlet />

            <FeedbackPopup
                open={confirmDeletionOpen}
                onConfirm={() => {
                    deletePaymentMethod();
                    setConfirmDeletionOpen(false);
                }}
                onAbort={() => setConfirmDeletionOpen(false)}
                confirmLabel={
                    <Localized
                        de="Löschen"
                        fr="Supprimer"
                        it="Elimina"
                        en="Delete"
                    />
                }
                abortLabel={
                    <Localized
                        de="Zurück"
                        fr="Retourner"
                        it="Indietro"
                        en="Back"
                    />
                }
                title={
                    <Localized
                        de="Referenz löschen"
                        fr="Supprimer référence"
                        it="Elimina riferimento"
                        en="Delete reference"
                    />
                }
            >
                <p>
                    <Localized
                        de="Im Parkingabo-System wird eine sichere und anonyme Referenz auf das Zahlungsmittel gespeichert; alle Details sind nur im Bankrechenzentrum unseres Zahlungsanbieters gespeichert."
                        fr="Seule une référence sécurisée et anonyme au moyen de paiement est stockée dans le système Parkingabo; tous les détails du moyen de paiement ne sont stockées que dans le centre de traitement des données bancaires de notre prestataire de paiement."
                        it="Nel sistema Parkingabo viene memorizzato un riferimento sicuro e anonimo al mezzo di pagamento; tutti i dettagli relativi a quest'ultimo sono memorizzati solo nel centro d'elaborazione dati di tipo bancario del nostro provider di pagamenti."
                        en="Only a secure and anonymous reference to the payment means is stored in the Parkingabo system; all details are only stored in the bank datacenter of our payment provider."
                    />
                </p>
                <p>
                    <Localized
                        de="Möchten Sie diese Referenz trotzdem löschen?"
                        fr="Voulez-vous quand même supprimer cette référence?"
                        it="Vuole eliminare comunque questo riferimento?"
                        en="Would you still like to delete this reference?"
                    />
                </p>
            </FeedbackPopup>
            <BlockingLoadingModal
                open={deleteState.status === RequestStatus.PENDING}
            />
        </ParkingaboLayoutWithHeader>
    );
}

function PaymentMethodIcon({
    paymentMethod,
}: {
    paymentMethod: PaymentCardType;
}) {
    switch (paymentMethod) {
        case PaymentCardType.VIS:
            return <PaymentIcon icon="vis" />;
        case PaymentCardType.AMX:
            return <PaymentIcon icon="amx" />;
        case PaymentCardType.ECA:
            return <PaymentIcon icon="eca" />;
        case PaymentCardType.TWI:
            return <PaymentIcon icon="twi" />;
        case PaymentCardType.PFC:
        case PaymentCardType.PEF:
            return <PaymentIcon icon="pfc" />;
    }
}

interface AliasRemovability {
    isRemovable: boolean;
}

function PaymentAliasItems({
    alias,
    aliasInvalidReason,
    onDelete,
}: {
    alias: CreditCardAliasFound;
    aliasInvalidReason: AliasInvalidReason | null;
    onDelete: () => void;
}) {
    const [aliasDeletionNotPossibleError, setAliasDeletionNotPossibleError] =
        useState(false);
    const generateAuthedParkingaboPath = useParkingaboAuthedPathGeneration();

    const [aliasRemovabilityState] =
        useParkingaboServerFetch<AliasRemovability>(
            () => ({
                url: `user/self/payment/alias-removability`,
            }),
            {},
        );

    const aliasExpiration = alias.creditCardAlias.expiration;
    return (
        <Box>
            <ParkingaboMenuListItem
                supText={<Localized de="Typ" fr="Type" it="Tipo" en="Type" />}
                text={
                    <Localized
                        {...paymentMethodTranslations[
                            alias.creditCardAlias.paymentMethod
                        ]}
                    />
                }
                icons={[
                    <PaymentMethodIcon
                        key={alias.creditCardAlias.paymentMethod}
                        paymentMethod={alias.creditCardAlias.paymentMethod}
                    />,
                ]}
            />
            {hasCardNumber(alias.creditCardAlias.paymentMethod) && (
                <ParkingaboMenuListItem
                    supText={
                        <Localized
                            de="Kartennummer"
                            fr="Numéro de la carte"
                            it="Numero della carta"
                            en="Card number"
                        />
                    }
                    text={alias.creditCardAlias.maskedCreditCardNumber}
                />
            )}
            {aliasExpiration && (
                <ParkingaboMenuListItem
                    supText={
                        <Localized
                            de="Ablaufdatum"
                            fr="Date d'échéance"
                            it="Data di scadenza"
                            en="Expiration date"
                        />
                    }
                    text={
                        <Box
                            component="span"
                            sx={{
                                color: theme =>
                                    aliasExpiration.isExpired
                                        ? theme.palette.error.main
                                        : undefined,
                            }}
                        >
                            {`${aliasExpiration.month} / ${aliasExpiration.year}`}
                        </Box>
                    }
                    icons={
                        aliasExpiration.isExpired
                            ? [<Error key={0} color="error" />]
                            : undefined
                    }
                />
            )}
            <ParkingaboMenuListItem
                supText={
                    <Localized de="Status" fr="État" it="Stato" en="State" />
                }
                text={<AliasState aliasInvalidReason={aliasInvalidReason} />}
            />

            <ParkingaboMenuListItem
                text={
                    <Localized
                        de="Ersetzen"
                        fr="Remplacer"
                        it="Sostituisci"
                        en="Replace"
                    />
                }
                icons={[<Sync key={0} />]}
                hideChevron
                to={generateAuthedParkingaboPath('settings/payment/replace')}
            />

            <ParkingaboAsyncLoadedSection
                state={aliasRemovabilityState}
                render={aliasRemovability => {
                    return (
                        <ParkingaboMenuListItem
                            text={
                                <Localized
                                    de="Löschen"
                                    fr="Effacer"
                                    it="Elimina"
                                    en="Delete"
                                />
                            }
                            icons={[<Delete key={0} />]}
                            hideChevron
                            onClick={
                                aliasRemovability.isRemovable
                                    ? onDelete
                                    : () =>
                                          setAliasDeletionNotPossibleError(true)
                            }
                        />
                    );
                }}
            />

            <FeedbackPopup
                open={aliasDeletionNotPossibleError}
                color="error"
                onConfirm={() => {
                    setAliasDeletionNotPossibleError(false);
                }}
                title={
                    <Localized
                        de="Löschung nicht möglich"
                        fr="Suppression pas possible"
                        it="Eliminazione non possibile"
                        en="Deletion not possible"
                    />
                }
            >
                <Localized
                    de="Es gibt derzeit Produkte, für die die Zahlung noch nicht erfolgt ist. Dies wird am Ende des laufenden Monats geschehen."
                    fr="Il y a actuellement des produits pour lesquels le paiement n'a encore été effectué. Cela se fera à la fin du mois en cours."
                    it="Attualmente ci sono dei prodotti per i quali non è ancora stato effettuato il pagamento. Ciò avverrà alla fine del mese corrente."
                    en="There are currently products for which the payment hasn’t yet been made. This will happen at the end of the current month."
                />
                <br />
                <br />
                <Localized
                    de="Bitte beachten Sie, dass bei einer allfälligen Schließung Ihres Parkingabo-Kontos durch den Betreiber, die Zahlungsmittel automatisch gelöscht wird."
                    fr="Veuillez noter qu'en cas de fermeture éventuelle de votre compte Parkingabo par l’exploitant, le moyen de paiement sera automatiquement supprimé."
                    it="Tenga presente che, nel caso in cui il suo conto Parkingabo venisse chiuso dal gestore, il mezzo di pagamento verrà automaticamente eliminato."
                    en="Please note that if your Parkingabo account is closed by the operator, the means of payment will be automatically cancelled."
                />
            </FeedbackPopup>
        </Box>
    );
}

function AliasState({
    aliasInvalidReason,
}: {
    aliasInvalidReason: AliasInvalidReason | null;
}): JSX.Element {
    if (aliasInvalidReason === null) {
        return <Localized de="Gültig" fr="Valide" it="Valido" en="Valid" />;
    }

    switch (aliasInvalidReason) {
        case AliasInvalidReason.EXPIRED:
            return (
                <InvalidAlias>
                    <Localized
                        de="Ungültig (abgelaufen)"
                        fr="Invalide (échu)"
                        it="Non valido (scaduto)"
                        en="Not valid (expired)"
                    />
                </InvalidAlias>
            );
        case AliasInvalidReason.LAST_DIRECT_FAILED:
            return (
                <InvalidAlias>
                    <Localized
                        de="Ungültig (abgelehnt)"
                        fr="Invalide (rejeté)"
                        it="Non valido (rifiutato)"
                        en="Not valid (rejected)"
                    />
                </InvalidAlias>
            );
        case AliasInvalidReason.NOT_FOUND:
        case AliasInvalidReason.ONBOARDING_REQUIRED:
            console.error(
                'User opened payment details without means of payment',
            );
            return (
                <InvalidAlias>
                    <Localized
                        de="Ungültig"
                        fr="Invalide"
                        it="Non valido"
                        en="Not valid"
                    />
                </InvalidAlias>
            );
    }
}

function InvalidAlias({ children }: { children: JSX.Element }): JSX.Element {
    return (
        <Box
            component="span"
            sx={{
                color: theme => theme.palette.error.main,
            }}
        >
            {children}
        </Box>
    );
}

function hasCardNumber(paymentMethod: PaymentCardType): boolean {
    switch (paymentMethod) {
        case PaymentCardType.VIS:
        case PaymentCardType.ECA:
        case PaymentCardType.AMX:
        case PaymentCardType.PFC:
            return true;
        case PaymentCardType.TWI:
        case PaymentCardType.PEF:
            return false;
    }
}
