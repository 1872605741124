import { UserDataForm } from '../../../components/forms/UserDataForm';

import { BaseModal } from './EditProfileRoute';
import { useCustomerTenant } from '../../../components/CustomerTenantProvider.tsx';

export function EditLanguageRoute() {
    const { user, refetchUser } = useCustomerTenant();
    return (
        <BaseModal
            open={true}
            user={user}
            refetchUser={refetchUser}
            render={(control, requestState, submit) => (
                <UserDataForm.LanguageField
                    control={control}
                    requestStatus={requestState.status}
                    submit={submit}
                />
            )}
            noCancelSaveButton={true}
        />
    );
}
