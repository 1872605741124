import { ReactNode, useMemo } from 'react';
import { Localized } from 'dg-web-shared/common/hooks/LanguageProvider';
import { ParkingaboLayoutWithHeader } from '../../../components/layout/ParkingaboLayoutWithHeader';
import { Outlet } from 'react-router-dom';
import { Box, Stack, Typography } from '@mui/material';
import {
    MenuListItemColorVariant,
    ParkingaboListChevron,
    ParkingaboListItem,
} from '../../../components/ParkingaboMenuListItem';
import {
    AccessTime,
    Add,
    CheckCircleOutline,
    DoDisturb,
    RemoveCircleOutline,
} from '@mui/icons-material';
import { Theme } from '@mui/material/styles';
import { Formatter } from 'dg-web-shared/lib/Date';
import { DateTime } from 'luxon';
import {
    ParkingaboProductListItem,
    ParkingaboProductState,
} from '../../../shared/ParkingaboProductModels';
import {
    StatusBar,
    StatusBarColorVariants,
} from '../../../components/StatusBar';
import { useForceProductVisibility } from '../../../hooks/UseForceProductVisibility.tsx';
import { SxProps } from '@mui/system';
import { useCustomerTenant } from '../../../components/CustomerTenantProvider.tsx';

export function ProductsRoute() {
    const { products, user } = useCustomerTenant();

    const forceVisibility = useForceProductVisibility();
    const activeProducts = useMemo(
        () =>
            products.filter(
                product => product.state === ParkingaboProductState.ACTIVE,
            ),
        [products],
    );
    const futureProducts = useMemo(
        () =>
            products.filter(
                product => product.state === ParkingaboProductState.FUTURE,
            ),
        [products],
    );
    const expiredProducts = useMemo(
        () =>
            products.filter(
                product => product.state === ParkingaboProductState.EXPIRED,
            ),
        [products],
    );
    return (
        <ParkingaboLayoutWithHeader
            noGutter
            scrollable
            backTo={'..'}
            title={
                <Localized
                    de="Produkte"
                    fr="Produits"
                    it="Prodotti"
                    en="Products"
                />
            }
        >
            <Stack
                direction="column"
                justifyContent="flex-start"
                alignItems="stretch"
                spacing={0}
                sx={{ height: '100%' }}
            >
                {forceVisibility || user.hasProductAvailable ? (
                    <ProductsListCategory>
                        <ParkingaboListItem
                            to={'add'}
                            colorVariant={MenuListItemColorVariant.PRIMARY}
                        >
                            <Stack
                                direction="row"
                                justifyContent="space-between"
                                alignItems="stretch"
                                spacing={3}
                                sx={{ width: '100%' }}
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        flexGrow: 1,
                                        textTransform: 'uppercase',
                                    }}
                                >
                                    <Localized
                                        de="Produkt hinzufügen"
                                        fr="Ajouter un produit"
                                        it="Aggiungi prodotto"
                                        en="Add product"
                                    />
                                </Box>
                                <Add
                                    style={{ fontSize: 36 }}
                                    color={'primary'}
                                />
                            </Stack>
                        </ParkingaboListItem>
                    </ProductsListCategory>
                ) : (
                    <Box sx={{ height: '10px', width: '100%' }} />
                )}

                {products.length < 1 ? (
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '100%',
                            height: '100%',
                        }}
                    >
                        <DoDisturb fontSize="large" />
                        <Typography
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                padding: '20px',
                            }}
                        >
                            <b>
                                <Localized
                                    de="Keine gültigen Produkte"
                                    fr="Aucun produit valable"
                                    it="Nessun prodotto valido"
                                    en="No valid products"
                                />
                            </b>
                        </Typography>
                    </Box>
                ) : (
                    <>
                        <ActiveProductsCategory
                            activeProducts={activeProducts}
                        />
                        <FutureProductsCategory
                            futureProducts={futureProducts}
                        />
                        <ExpiredProductsCategory
                            expiredProducts={expiredProducts}
                        />
                    </>
                )}
            </Stack>
            <Outlet />
        </ParkingaboLayoutWithHeader>
    );
}

function ActiveProductsCategory({
    activeProducts,
}: {
    activeProducts: ParkingaboProductListItem[];
}) {
    if (activeProducts.length < 1) {
        return null;
    }

    return (
        <ProductsListCategory>
            <>
                <StatusBar colorVariant={StatusBarColorVariants.SUCCESS}>
                    <Localized
                        de="Gültige"
                        fr="Valables"
                        it="Validi"
                        en="Valid"
                    />
                </StatusBar>
                {activeProducts.map(product => (
                    <ParkingaboListItem
                        to={product.contractId.toString(10)}
                        key={product.contractId}
                        variableHeight
                        colorVariant={MenuListItemColorVariant.SUCCESS}
                        showBottomBorder={false}
                    >
                        <ProductElement
                            validityIcon={
                                <CheckCircleOutline fontSize="large" />
                            }
                            sx={{ color: theme => theme.palette.success.main }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    flex: 1,
                                    flexDirection: 'column',
                                    maxWidth: '100%',
                                }}
                            >
                                <Typography
                                    component="h2"
                                    fontWeight="bold"
                                    paddingBottom={1}
                                    noWrap
                                >
                                    <Localized {...product.name} />
                                </Typography>
                                {product.zones.length < 3 ? (
                                    product.zones.map(zone => (
                                        <Typography
                                            variant="body2"
                                            noWrap
                                            key={zone.zoneId}
                                        >
                                            {zone.name}
                                        </Typography>
                                    ))
                                ) : (
                                    <Typography variant="body2">
                                        {product.zones.length}{' '}
                                        <Localized
                                            de="Parkings"
                                            fr="parkings"
                                            it="parcheggi"
                                            en="parkings"
                                        />
                                    </Typography>
                                )}
                                <Typography variant="body2">
                                    {product.validTo ? (
                                        <>
                                            <Localized
                                                de="bis"
                                                fr="jusqu'au"
                                                it="fino al"
                                                en="to"
                                            />{' '}
                                            {Formatter.dayMonthYearHourMinute(
                                                DateTime.fromISO(
                                                    product.validTo,
                                                ),
                                            )}
                                        </>
                                    ) : (
                                        <Localized
                                            de="bis auf Widerruf"
                                            fr="jusqu'à révocation"
                                            it="fino a revoca"
                                            en="until recall"
                                        />
                                    )}
                                </Typography>
                            </Box>
                        </ProductElement>
                    </ParkingaboListItem>
                ))}
            </>
        </ProductsListCategory>
    );
}

function FutureProductsCategory({
    futureProducts,
}: {
    futureProducts: ParkingaboProductListItem[];
}) {
    if (futureProducts.length < 1) {
        return null;
    }

    return (
        <ProductsListCategory>
            <>
                <StatusBar colorVariant={StatusBarColorVariants.WARNING}>
                    <Localized
                        de="Zukünftig gültige"
                        fr="Valables dans le futur"
                        it="Validi in futuro"
                        en="Valid in the future"
                    />
                </StatusBar>
                {futureProducts.map(product => (
                    <ParkingaboListItem
                        to={product.contractId.toString(10)}
                        key={product.contractId}
                        colorVariant={MenuListItemColorVariant.WARNING}
                        showBottomBorder={false}
                    >
                        <ProductElement
                            validityIcon={<AccessTime fontSize="large" />}
                            sx={{ color: theme => theme.palette.warning.main }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    flex: 1,
                                    flexDirection: 'column',
                                    maxWidth: '100%',
                                }}
                            >
                                <Typography
                                    component="h2"
                                    fontWeight="bold"
                                    noWrap
                                >
                                    <Localized {...product.name} />
                                </Typography>
                                <Typography variant="body2">
                                    <Localized
                                        de="ab"
                                        fr="à partir du"
                                        it="dal"
                                        en="from"
                                    />{' '}
                                    {Formatter.dayMonthYearHourMinute(
                                        DateTime.fromISO(product.validFrom),
                                    )}
                                </Typography>
                            </Box>
                        </ProductElement>
                    </ParkingaboListItem>
                ))}
            </>
        </ProductsListCategory>
    );
}

function ExpiredProductsCategory({
    expiredProducts,
}: {
    expiredProducts: ParkingaboProductListItem[];
}) {
    if (expiredProducts.length < 1) {
        return null;
    }

    return (
        <ProductsListCategory>
            <>
                <StatusBar
                    rightLabel={
                        <Localized
                            de="letzte 30 Tage"
                            fr="derniers 30 jours"
                            it="ultimi 30 giorni"
                            en="last 30 days"
                        />
                    }
                    colorVariant={StatusBarColorVariants.DISABLED}
                >
                    <Localized
                        de="Abgelaufene"
                        fr="Échus"
                        it="Scaduti"
                        en="Expired"
                    />
                </StatusBar>
                {expiredProducts.map(product => (
                    <ParkingaboListItem
                        to={product.contractId.toString(10)}
                        key={product.contractId}
                        colorVariant={MenuListItemColorVariant.DISABLED}
                        showBottomBorder={false}
                    >
                        <ProductElement
                            validityIcon={
                                <RemoveCircleOutline fontSize="large" />
                            }
                            sx={{ color: theme => theme.palette.grey['600'] }}
                        >
                            <Typography
                                component="h2"
                                fontWeight="bold"
                                color="text.disabled"
                                noWrap
                            >
                                <Localized {...product.name} />
                            </Typography>
                        </ProductElement>
                    </ParkingaboListItem>
                ))}
            </>
        </ProductsListCategory>
    );
}

function ProductsListCategory({ children }: { children: JSX.Element }) {
    return <Box sx={{ margin: '15px 15px 0 15px' }}>{children}</Box>;
}

function ProductElement({
    children,
    validityIcon,
    sx,
}: {
    children: ReactNode;
    validityIcon: JSX.Element;
    sx: SxProps<Theme>;
}) {
    return (
        <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="stretch"
            spacing={3}
            sx={{ width: '100%', ...sx }}
        >
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flexShrink: 0,
                }}
            >
                {validityIcon}
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flex: '1 1 auto',
                    minWidth: 0,
                    flexWrap: 'wrap',
                    marginLeft: '18px !important',
                }}
            >
                {children}
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flexShrink: 0,
                    marginLeft: '0px !important',
                }}
            >
                <ParkingaboListChevron />
            </Box>
        </Stack>
    );
}
