import { useEffect, useMemo, useState } from 'react';
import { Localized } from 'dg-web-shared/common/hooks/LanguageProvider';
import {
    Box,
    FormControlLabel,
    Switch,
    TextField,
    Typography,
} from '@mui/material';
import { DateTime } from 'luxon';
import {
    formatZoneName,
    ParkingaboProductTemplateGuestReservation,
} from './ParkingaboProductTemplateModels';
import {
    WizardBody,
    WizardBottomBar,
    WizardFadeInEffect,
    WizardProductHeader,
    WizardStepper,
} from './WizardComponents';
import { useNavigate } from 'react-router-dom';
import { useParkingaboServerWrite } from '../../../api/ParkingaboApi';
import {
    RequestStatus,
    useServerSuccessEffect,
} from 'dg-web-shared/lib/hooks/ServerStateHooks';
import { BackendRequestErrorMessage } from 'dg-web-shared/common/components/material-ui/BackendRequestErrorMessage';

import { FeedbackPopup } from '../../../components/FeedbackPopup';
import { ParkingaboOverlineList } from '../../../components/layout/ParkingaboOverlineList';
import { ParkingaboZoneInfo } from '../../../shared/ParkingaboProductModels';
import { TimeSelectionStep, MultiDateSelectionStep } from './SharedSteps';
import { SelectableList } from '../../../components/SelectableList';
import { TimeSelection } from './ParkingaboTimeSelector';
import { VehicleType } from './ProductsConfigurationOutlet';
import { useParkingaboAuthedPathGeneration } from '../../RouteUtils';
import { css } from '@emotion/css';

enum WizardStep {
    DAY,
    START_TIME,
    DURATION,
    OVERVIEW,
}

interface WizardSelection {
    day: DateTime | null;
    startDateTime: DateTime | null;
    endDateTime: DateTime | null;
    purchaseDisclaimerChecked: boolean;
    customerRemark: string | null;
}

export function ProductsConfigurationGuestReservation({
    product,
    zones,
    noProductChange,
    refetchProducts,
}: {
    product: ParkingaboProductTemplateGuestReservation;
    zones: ParkingaboZoneInfo[];
    noProductChange: boolean;
    refetchProducts: () => void;
}) {
    const [selection, setSelection] = useState<WizardSelection>({
        day: null,
        startDateTime: null,
        endDateTime: null,
        customerRemark: null,
        purchaseDisclaimerChecked: false,
    });

    const [purchaseState, purchaseProduct, resetPurchaseState] =
        useParkingaboServerWrite<
            {
                contractTemplateId: number;
                start: string;
                end: string;
                remark: string | null;
            },
            never
        >(() => ({
            url: `user/self/product/purchase/guest-reservation`,
        }));

    useServerSuccessEffect(purchaseState, refetchProducts);

    const navigate = useNavigate();

    useEffect(() => {
        if (purchaseState.status === RequestStatus.ERROR) {
            resetPurchaseState();
        }
    }, [selection]);

    function handlePurchase() {
        if (
            !selection.startDateTime ||
            !selection.endDateTime ||
            activeStep !== WizardStep.OVERVIEW
        ) {
            throw new Error(
                'Tried to purchase product without completed selection',
            );
        }
        purchaseProduct({
            contractTemplateId: product.contractTemplateId,
            start: selection.startDateTime.toISO(),
            end: selection.endDateTime.toISO(),
            remark: selection.customerRemark,
        });
    }

    function combineDayAndTime(day: DateTime, hour: number, minute: number) {
        return day.set({
            hour: hour,
            minute: minute,
            second: 0,
            millisecond: 0,
        });
    }

    const steps = [
        {
            step: WizardStep.DAY,
            label: <Localized de="Datum" fr="Date" it="Data" en="Date" />,
        },
        {
            step: WizardStep.START_TIME,
            label: <Localized de="Start" fr="Début" it="Inizio" en="Start" />,
        },
        {
            step: WizardStep.DURATION,
            label: (
                <Localized de="Dauer" fr="Durée" it="Durata" en="Duration" />
            ),
        },
        {
            step: WizardStep.OVERVIEW,
            label: (
                <Localized
                    de="Übersicht"
                    fr="Aperçu"
                    it="Panoramica"
                    en="Overview"
                />
            ),
        },
    ];

    const firstStep = steps[0].step;
    const [activeStep, setActiveStep] = useState<WizardStep>(firstStep);

    const stepsWithClearance = useMemo(
        () => determineStepsWithClearance(product, selection),
        [product, selection],
    );

    const [timeSelection, setTimeSelection] = useState<TimeSelection>({
        hour: null,
        minute: null,
    });

    const generateAuthedParkingaboPath = useParkingaboAuthedPathGeneration();

    return (
        <>
            <BackendRequestErrorMessage requestState={purchaseState} />
            <PurchaseSuccessDialog
                open={purchaseState.status === RequestStatus.SUCCESS}
                onAbort={() =>
                    navigate(generateAuthedParkingaboPath('products'))
                }
            />
            <WizardProductHeader>
                <Localized {...product.name} />
            </WizardProductHeader>
            <WizardStepper
                steps={steps}
                stepsWithClearance={stepsWithClearance}
                activeStep={activeStep}
                onStepClick={setActiveStep}
                offset={noProductChange ? 0 : 3}
            />
            <WizardBody>
                {activeStep === WizardStep.DAY && (
                    <MultiDateSelectionStep
                        dates={selection.day ? [selection.day] : []}
                        onChange={dates => {
                            setTimeSelection({ hour: null, minute: null });
                            setSelection({
                                ...selection,
                                day: dates[0],
                                startDateTime: null,
                                endDateTime: null,
                            });
                            if (dates[0]) {
                                setActiveStep(activeStep + 1);
                            }
                        }}
                        minValidityDate={
                            product.minValidityStart
                                ? DateTime.max(
                                      DateTime.fromISO(
                                          product.minValidityStart,
                                      ),
                                      DateTime.now(),
                                  )
                                : null
                        }
                        maxSelectableDates={1}
                        maxDaysInFuture={product.maxDaysPurchaseAheadInFuture}
                    />
                )}
                {activeStep === WizardStep.START_TIME && (
                    <TimeSelectionStep
                        date={selection.day!}
                        time={timeSelection}
                        onTimeChange={(time, userInteraction) => {
                            setTimeSelection(time);
                            const dateTimeToSet =
                                time.hour != null && time.minute != null
                                    ? combineDayAndTime(
                                          selection.day!,
                                          time.hour,
                                          time.minute,
                                      )
                                    : null;
                            setSelection(s => ({
                                ...s,
                                startDateTime: dateTimeToSet,
                            }));
                            if (
                                time.hour != null &&
                                time.minute != null &&
                                userInteraction
                            ) {
                                setActiveStep(activeStep + 1);
                            }
                        }}
                    />
                )}
                {activeStep === WizardStep.DURATION && (
                    <DurationSelectionStep
                        product={product}
                        selectedStartDate={selection.startDateTime!}
                        selectedEndDate={selection.endDateTime}
                        onEndDateSelection={v => {
                            setSelection(s => ({
                                ...s,
                                endDateTime: v,
                            }));
                            if (v) {
                                setActiveStep(activeStep + 1);
                            }
                        }}
                    />
                )}
                {activeStep === WizardStep.OVERVIEW && (
                    <ProductOverview
                        product={product}
                        zones={zones}
                        from={selection.startDateTime!}
                        to={selection.endDateTime!}
                        purchaseDisclaimerChecked={{
                            set: v =>
                                setSelection(s => ({
                                    ...s,
                                    purchaseDisclaimerChecked: v,
                                })),
                            value: selection.purchaseDisclaimerChecked,
                        }}
                        customerRemark={{
                            set: (v: string) =>
                                setSelection(s => ({
                                    ...s,
                                    customerRemark: v,
                                })),
                            value: selection.customerRemark,
                        }}
                    />
                )}
            </WizardBody>
            <WizardBottomBar
                onNextClick={
                    stepsWithClearance.includes(activeStep + 1)
                        ? () => {
                              if (activeStep === WizardStep.OVERVIEW) {
                                  handlePurchase();
                              } else {
                                  setActiveStep(activeStep + 1);
                              }
                          }
                        : undefined
                }
                nextLabel={
                    activeStep === WizardStep.OVERVIEW ? (
                        <Localized
                            de="Buchen"
                            fr="Réserver"
                            it="Prenota"
                            en="Book"
                        />
                    ) : undefined
                }
                nextAsSubmitRequestState={
                    activeStep === WizardStep.OVERVIEW
                        ? purchaseState.status
                        : undefined
                }
                onPreviousClick={() => {
                    if (activeStep === firstStep) {
                        navigate('..');
                    } else {
                        setActiveStep(activeStep - 1);
                    }
                }}
                previousLabel={
                    activeStep === firstStep ? (
                        <Localized
                            de="Produkt ändern"
                            fr="Changer de produit"
                            it="Cambia prodotto"
                            en="Change product"
                        />
                    ) : undefined
                }
                hidePrevious={noProductChange && activeStep === firstStep}
            />
        </>
    );
}

function determineStepsWithClearance(
    product: ParkingaboProductTemplateGuestReservation,
    selection: WizardSelection,
) {
    const steps = Object.values(WizardStep).filter(
        (step): step is WizardStep =>
            typeof step !== 'string' && typeof step !== 'undefined',
    );
    const stepsIncludingLastNextStep = [...steps, steps[steps.length - 1] + 1];
    return stepsIncludingLastNextStep.filter(step => {
        switch (step) {
            case WizardStep.DAY:
                return true;
            case WizardStep.START_TIME:
                return !!selection.day;
            case WizardStep.DURATION:
                return !!selection.day && !!selection.startDateTime;
            case WizardStep.OVERVIEW:
                return (
                    !!selection.day &&
                    !!selection.startDateTime &&
                    !!selection.endDateTime
                );
            default:
                return (
                    !!selection.day &&
                    !!selection.startDateTime &&
                    !!selection.endDateTime &&
                    selection.purchaseDisclaimerChecked &&
                    (product.appPurchaseCustomerRemarkLabel === null ||
                        !!selection.customerRemark)
                );
        }
    });
}

function ProductOverview({
    product,
    zones,
    from,
    to,
    purchaseDisclaimerChecked,
    customerRemark,
}: {
    product: ParkingaboProductTemplateGuestReservation;
    zones: ParkingaboZoneInfo[];
    from: DateTime;
    to: DateTime;
    purchaseDisclaimerChecked: { value: boolean; set: (v: boolean) => void };
    customerRemark: { value: string | null; set: (v: string) => void };
}) {
    const productZones = zones.filter(zone =>
        product.zoneIds.includes(zone.zoneId),
    );

    return (
        <WizardFadeInEffect>
            <ParkingaboOverlineList.Body>
                <ParkingaboOverlineList.Item
                    label={
                        productZones.length > 1 ? (
                            <Localized
                                de="Parkings"
                                fr="Parkings"
                                it="Parcheggi"
                                en="Parkings"
                            />
                        ) : (
                            <Localized
                                de="Parking"
                                fr="Parking"
                                it="Parcheggio"
                                en="Parking"
                            />
                        )
                    }
                >
                    {productZones.map(zone => (
                        <Box
                            sx={{
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                            }}
                            key={zone.zoneId}
                        >
                            {formatZoneName(zone)}
                        </Box>
                    ))}
                </ParkingaboOverlineList.Item>
                <ParkingaboOverlineList.Item
                    label={
                        <Localized
                            de="Dauer"
                            fr="Durée"
                            it="Durata"
                            en="Duration"
                        />
                    }
                >
                    {from.toFormat('dd.MM.yyyy HH:mm')} -{' '}
                    {from.startOf('day').valueOf() ===
                    to.startOf('day').valueOf()
                        ? to.toFormat('HH:mm')
                        : to.toFormat('dd.MM.yyyy HH:mm')}
                </ParkingaboOverlineList.Item>
                {product.info && (
                    <ParkingaboOverlineList.Item
                        label={
                            <Localized
                                de="Info"
                                fr="Info"
                                it="Info"
                                en="Info"
                            />
                        }
                    >
                        <Localized {...product.info} />
                    </ParkingaboOverlineList.Item>
                )}
                <VehicleType vehicleType={product.vehicleType} />
                {product.appPurchaseCustomerRemarkLabel && (
                    <TextField
                        value={customerRemark.value || ''}
                        onChange={e => customerRemark.set(e.target.value)}
                        label={
                            <>
                                <Localized
                                    {...product.appPurchaseCustomerRemarkLabel}
                                />
                                <span
                                    className={css({
                                        color: 'blue',
                                        fontWeight: 'bold',
                                    })}
                                >
                                    *
                                </span>
                            </>
                        }
                        multiline
                        rows={4}
                        variant="filled"
                    />
                )}
                <FormControlLabel
                    control={
                        <Switch
                            checked={purchaseDisclaimerChecked.value}
                            onChange={(_, v) =>
                                purchaseDisclaimerChecked.set(v)
                            }
                        />
                    }
                    label={<Localized {...product.appPurchaseDisclaimerText} />}
                />
            </ParkingaboOverlineList.Body>
        </WizardFadeInEffect>
    );
}

function PurchaseSuccessDialog({
    open,
    onAbort,
}: {
    open: boolean;
    onAbort: () => void;
}) {
    return (
        <FeedbackPopup
            open={open}
            color="success"
            title={
                <Localized
                    de="Bestätigung"
                    fr="Confirmation"
                    it="Conferma"
                    en="Confirmation"
                />
            }
            abortLabel={'OK'}
            onAbort={onAbort}
        >
            <Localized
                de="Das Produkt wurde erfolgreich erworben."
                fr="Le produit a été acheté avec succès."
                it="Il prodotto è stata acquistato con successo."
                en="The product was successfully purchased."
            />
        </FeedbackPopup>
    );
}

function DurationSelectionStep({
    product,
    selectedStartDate,
    selectedEndDate,
    onEndDateSelection,
}: {
    product: ParkingaboProductTemplateGuestReservation;
    selectedStartDate: DateTime;
    selectedEndDate: DateTime | null;
    onEndDateSelection: (date: DateTime | null) => void;
}) {
    const selectionItems = useMemo(() => {
        return (
            Array.from(Array(product.maxHours), (_, i) => i + 1).map(hour => ({
                id: hour,
                content: (
                    <Typography fontWeight="bold">
                        {hour}{' '}
                        {hour === 1 ? (
                            <Localized
                                de="Stunde"
                                fr="heure"
                                it="ora"
                                en="hour"
                            />
                        ) : (
                            <Localized
                                de="Stunden"
                                fr="heures"
                                it="ore"
                                en="hours"
                            />
                        )}
                    </Typography>
                ),
            })) ?? []
        );
    }, [product]);

    function handleDurationChange(selectedIds: number[]) {
        if (selectedIds.length === 1) {
            const endDate = selectedStartDate.plus({ hour: selectedIds[0] });
            onEndDateSelection(endDate);
        } else {
            onEndDateSelection(null);
        }
    }

    return (
        <WizardFadeInEffect>
            <SelectableList
                selectionItems={selectionItems}
                initialSelection={
                    selectedEndDate
                        ? [
                              selectedEndDate.diff(selectedStartDate, ['hours'])
                                  .hours,
                          ]
                        : []
                }
                onChange={handleDurationChange}
                preventDeselect
            />
        </WizardFadeInEffect>
    );
}
