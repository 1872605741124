import { useParamOrNull } from 'dg-web-shared/lib/ReactRouterHelpers.ts';
import { Navigate, useNavigate } from 'react-router-dom';
import {
    RequestMethod,
    useNavigateOnError,
    useNavigateOnSuccess,
    useServerErrorEffect,
    useServerFetch,
    useServerSuccessEffect,
    useServerWrite,
} from 'dg-web-shared/lib/hooks/ServerStateHooks.ts';
import { useState } from 'react';
import {
    AuthedValidationContent,
    AuthValidationErrorReason,
    AuthValidationSuccess,
    AuthValidationSuccessResponse,
    LinkLoginData,
    LinkLoginPopup,
    ConfirmEmailPopup,
    OnboardingCompletePopup,
    OnboardingPendingPopup,
    PublicValidationErrorPopup,
    PublicValidationErrorReason,
    PublicValidationResponse,
    PublicValidationState,
    useResentVerificationMail,
    ValidationOrigin,
    ValidationState,
} from './ParkingaboSharedRegistrationValidationRoute.tsx';
import { ParkingaboAsyncLoadedSection } from '../../components/layout/ParkingaboAsyncLoadedSection.tsx';
import { LoggedInRouteCompProps } from '../RouteUtils.tsx';
import { ValidationData } from 'dg-web-shared/lib/forms/FormValidationHelpers.tsx';

export function usePublicCustomerValidationState(customerNr: string | null) {
    const [customerValidationState] = useServerFetch<
        PublicValidationResponse,
        { customerNr: string },
        { reason: PublicValidationErrorReason }
    >(
        ({ customerNr }) => ({
            url: `/ui-api/parkingabo/customer/${customerNr}/validation`,
        }),
        customerNr ? { customerNr: customerNr } : null,
    );

    return [customerValidationState];
}

export function PublicParkingaboCustomerValidationRoute() {
    const customerNr = useParamOrNull('customerNr');
    const navigate = useNavigate();
    const [customerValidationState] =
        usePublicCustomerValidationState(customerNr);

    const { anotherLinkState, requestAnotherLink } =
        useResentVerificationMail();
    useNavigateOnSuccess(
        anotherLinkState,
        `/register-with-customer/${customerNr}/email-sent`,
    );

    const [removeCustomerRegistrationState, removeCustomerRegistration] =
        useServerWrite<
            {
                customerNr: string;
                email: string;
            },
            never
        >(() => ({
            url: `/ui-api/parkingabo/customer/${customerNr}/remove-customer-registration`,
        }));
    useNavigateOnSuccess(
        removeCustomerRegistrationState,
        `/register-with-customer/${customerNr}`,
    );
    useNavigateOnError(removeCustomerRegistrationState, '/login');

    if (customerNr === null) {
        return <Navigate to="/login" />;
    }

    return (
        <ParkingaboAsyncLoadedSection
            state={customerValidationState}
            render={data => {
                switch (data.validationState) {
                    case PublicValidationState.NO_LOGIN:
                        return (
                            <Navigate
                                to={`/register-with-customer/${customerNr}`}
                            />
                        );
                    case PublicValidationState.EMAIL_CONFIRMATION_PENDING:
                        return (
                            <ConfirmEmailPopup
                                emailToBeConfirmed={data.email!}
                                onConfirm={() => () =>
                                    requestAnotherLink({
                                        email: data.email!,
                                    })
                                }
                                onAbort={() =>
                                    removeCustomerRegistration({
                                        customerNr: customerNr!,
                                        email: data.email!,
                                    })
                                }
                            />
                        );
                    case PublicValidationState.ONBOARDING_PENDING:
                        return (
                            <OnboardingPendingPopup
                                onConfirm={() => navigate('/login')}
                                origin={ValidationOrigin.CUSTOMER}
                            />
                        );
                    case PublicValidationState.ONBOARDING_COMPLETED:
                        return (
                            <OnboardingCompletePopup
                                onConfirm={() => navigate('/login')}
                                origin={ValidationOrigin.CUSTOMER}
                            />
                        );
                }
            }}
            renderError={errorData => (
                <PublicValidationErrorPopup
                    error={errorData}
                    origin={ValidationOrigin.CUSTOMER}
                />
            )}
        />
    );
}

export function AuthedParkingaboCustomerValidationRoute({
    logout,
}: LoggedInRouteCompProps) {
    const customerNr = useParamOrNull('customerNr');
    const navigate = useNavigate();
    const [linkLoginData, setLinkLoginData] = useState<LinkLoginData | null>(
        null,
    );
    const [validationState, setValidationState] = useState<ValidationState>(
        ValidationState.LOADING,
    );

    const [customerValidationState] = useServerFetch<
        AuthValidationSuccess,
        { customerNr: string },
        { reason: AuthValidationErrorReason }
    >(
        ({ customerNr }) => ({
            url: `/ui-api/parkingabo/user/customer/${customerNr}/validation`,
        }),
        customerNr ? { customerNr: customerNr } : null,
    );

    const [linkCustomerToLoginState, linkCustomerToLogin] = useServerWrite<
        { customerNr: string },
        object,
        ValidationData
    >(() => ({
        url: `/ui-api/parkingabo/user/customer/${customerNr}/link-login`,
        method: RequestMethod.PUT,
    }));

    useServerSuccessEffect(customerValidationState, data => {
        switch (data.response) {
            case AuthValidationSuccessResponse.LINK_LOGIN:
                setLinkLoginData({
                    email: data.email,
                    operatorName: data.operatorName,
                });
                break;
            case AuthValidationSuccessResponse.LOGGED_IN:
                navigate(`/${data.tenantId}`);
                break;
        }
    });

    useServerErrorEffect(customerValidationState, (_, data) => {
        if (data === null) {
            setValidationState(ValidationState.GENERIC_ERROR);
        } else {
            switch (data.reason) {
                case AuthValidationErrorReason.NOT_FOUND:
                    setValidationState(ValidationState.NOT_FOUND);
                    break;
                case AuthValidationErrorReason.ALREADY_REGISTERED_ON_OPERATOR:
                    setValidationState(
                        ValidationState.ALREADY_REGISTERED_ON_OPERATOR,
                    );
                    break;
            }
        }
    });

    if (customerNr === null) {
        return <Navigate to="/" replace />;
    }

    if (linkLoginData !== null) {
        return (
            <LinkLoginPopup
                linkLoginData={linkLoginData}
                onConfirm={() =>
                    linkCustomerToLogin({ customerNr: customerNr })
                }
                linkCustomerToLoginState={linkCustomerToLoginState}
                onAbort={() => logout()}
            />
        );
    }

    return (
        <AuthedValidationContent
            validationState={validationState}
            origin={ValidationOrigin.CUSTOMER}
        />
    );
}
