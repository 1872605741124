import { Box } from '@mui/material';
import { CSSProperties, ReactNode } from 'react';

export function ParkingaboScrollableLayout({
    stickyHeader,
    bottomFooter,
    children,
}: {
    stickyHeader?: ReactNode;
    bottomFooter?: ReactNode;
    children: ReactNode;
}) {
    return (
        <Box
            sx={{
                position: 'relative',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: theme => theme.palette.primary.light,
            }}
        >
            {stickyHeader}
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    flexGrow: 1,
                    overflow: 'hidden',
                    overflowY: 'auto',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        flexGrow: 1,
                    }}
                >
                    {children}
                </Box>
                {bottomFooter}
            </Box>
        </Box>
    );
}

export function ParkingaboLayout({
    children,
    style,
}: {
    children?: ReactNode | ReactNode[];
    style?: CSSProperties;
}) {
    return (
        <Box
            sx={theme => ({
                ...layoutStyles(),
                padding: theme.spacing(2),
                ...style,
            })}
        >
            {children}
        </Box>
    );
}

export function layoutStyles() {
    return {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        flexGrow: 1,
        overflowY: 'auto',
        height: '100%',
    };
}
