import { RequestStatus } from 'dg-web-shared/lib/hooks/ServerStateHooks';
import { ParkingaboVehicle } from 'dg-web-shared/common/models/Vehicle';
import { ParkingaboUser, Tenant } from '../shared/ParkingaboModels';
import { useParams } from 'react-router';
import { ParkingaboProductListItem } from '../shared/ParkingaboProductModels';

export interface Vehicles {
    data: ParkingaboVehicle[];
    status: RequestStatus;
    refetch: () => void;
}

export interface CustomerTenantContextData {
    user: ParkingaboUser;
    linkedUserCount: number;
    refetchUser: () => void;
    products: ParkingaboProductListItem[];
    refetchProducts: () => void;
    vehicles: Vehicles;
    tenant: Tenant;
    logout: () => void;
}

export type LoggedInRouteCompProps = {
    logout: () => void;
};

export function useParkingaboAuthedPathGeneration() {
    const { tenantId } = useParams<{ tenantId?: string }>();

    function generateAuthedParkingaboPath(path: string): string {
        return `/${tenantId}/` + path;
    }
    return generateAuthedParkingaboPath;
}
