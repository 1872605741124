import { createContext, useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

type QRCodeScanner = () => void;

interface NativeBridge {
    scanQRCode: QRCodeScanner | null;
    appTokenV2: (
        appToken: string | null,
        uuid: string,
        version: string,
    ) => void;
}

export const getBridge = (): NativeBridge | null => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const w: any = window;
    if (w.android) {
        return w.android;
    } else if (w.webkit && w.webkit.messageHandlers) {
        return {
            scanQRCode: scanQRCodeHandler(),
            appTokenV2: getAppTokenHandler(),
        };
    } else {
        return null;
    }
};

function scanQRCodeHandler(): QRCodeScanner | null {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const w: any = window;
    if (
        w.webkit &&
        w.webkit.messageHandlers &&
        w.webkit.messageHandlers.scanQRCode
    ) {
        return () => w.webkit.messageHandlers.scanQRCode.postMessage(null);
    }
    return null;
}

function getAppTokenHandler() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const w: any = window;
    if (
        w.webkit &&
        w.webkit.messageHandlers &&
        w.webkit.messageHandlers.appToken
    ) {
        return (appToken: string | null, uuid: string, appVersion: string) =>
            w.webkit.messageHandlers.appToken.postMessage({
                appToken,
                uuid,
                appVersion,
            });
    }
    return () => null;
}

export function setAppToken(appToken: string | null) {
    const bridge = getBridge();
    if (bridge && bridge.appTokenV2) {
        bridge.appTokenV2(appToken, 'no_uuid', SENTRY_RELEASE.id);
    }
}

const enum NativeDeviceMessageType {
    QRScannerCallback = 'QRScannerCallback',
}

type MessageFromNativeDeviceCallback = (
    type: NativeDeviceMessageType,
    message: string,
) => void;

type WindowWithBridge = {
    messageFromNativeDevice: MessageFromNativeDeviceCallback;
} & Window &
    typeof globalThis;

const NativeContext = createContext({});

export function NativeContextProdivder() {
    const navigate = useNavigate();
    useEffect(() => {
        (window as WindowWithBridge).messageFromNativeDevice = (
            type: NativeDeviceMessageType,
            message: string,
        ) => {
            switch (type) {
                case NativeDeviceMessageType.QRScannerCallback: {
                    const pathname = parseToParkingaboURL(message);
                    if (!pathname) {
                        navigate('/login/qr-code-parsing-failed');
                        return 'ok';
                    }
                    navigate(pathname);
                    return 'ok';
                }
                default:
                    return 'unknownMessageFromDevice';
            }
        };
    }, []);

    return (
        <NativeContext.Provider value={{}}>
            <Outlet />
        </NativeContext.Provider>
    );
}

function parseToParkingaboURL(message: string): string | null {
    try {
        const url = new URL(message);
        return getParkingaboPathname(url);
    } catch {
        return null;
    }
}

function getParkingaboPathname(url: URL) {
    if (
        !url.host.match(
            /^parkingabo\.ch$|^parkingabo\.test\.digitalparking\.ch$|^parkingabo\.([\w-]+)\.digitalparking\.dev$/,
        )
    ) {
        return null;
    }
    return url.pathname;
}
