import { ReactHookFormSelectOption } from 'dg-web-shared/common/components/material-ui/react-hook-form-fields/ReactHookFormSelect';
import { LicensePlateCountry } from 'dg-web-shared/dto/LicensePlateCountry';
import { useServerFetch } from 'dg-web-shared/lib/hooks/ServerStateHooks.ts';

export function useCountryFetch() {
    return useServerFetch<LicensePlateCountry[], object>(
        () => ({
            url: `/ui-api/meta/license-plate-countries`,
        }),
        {},
    );
}

export function makeCountryOptions(
    countries: LicensePlateCountry[],
): ReactHookFormSelectOption<string>[] {
    return countries.map<ReactHookFormSelectOption<string>>(c => ({
        value: c.id,
        display: c.id,
    }));
}
