import { useParamOrNull } from 'dg-web-shared/lib/ReactRouterHelpers.ts';
import { Navigate, useNavigate } from 'react-router-dom';
import {
    RequestMethod,
    useServerErrorEffect,
    useServerFetch,
    useServerSuccessEffect,
    useServerWrite,
} from 'dg-web-shared/lib/hooks/ServerStateHooks.ts';
import { useState } from 'react';
import {
    AuthedValidationContent,
    AuthValidationErrorReason,
    AuthValidationSuccess,
    AuthValidationSuccessResponse,
    LinkLoginData,
    LinkLoginPopup,
    ValidationOrigin,
    ValidationState,
} from './ParkingaboSharedRegistrationValidationRoute.tsx';
import { LoggedInRouteCompProps } from '../RouteUtils.tsx';
import { ValidationData } from 'dg-web-shared/lib/forms/FormValidationHelpers.tsx';

export function AuthedParkingaboTenantRegistrationValidationRoute({
    logout,
}: LoggedInRouteCompProps) {
    const tenantId = Number(useParamOrNull('tenantId'));
    const navigate = useNavigate();
    const [validationState, setValidationState] = useState<ValidationState>(
        ValidationState.LOADING,
    );
    const [linkLoginData, setLinkLoginData] = useState<LinkLoginData | null>(
        null,
    );

    const [customerValidationState] = useServerFetch<
        AuthValidationSuccess,
        { tenantId: number },
        { reason: AuthValidationErrorReason }
    >(
        ({ tenantId }) => ({
            url: `/ui-api/parkingabo/user/tenant/${tenantId}/validation`,
        }),
        tenantId ? { tenantId: tenantId } : null,
    );

    const [
        createAndLinkTenantCustomerToLoginState,
        createAndLinkTenantCustomerToLogin,
    ] = useServerWrite<{ tenantId: number }, object, ValidationData>(() => ({
        url: `/ui-api/parkingabo/user/tenant/${tenantId}/link-login`,
        method: RequestMethod.POST,
    }));

    useServerSuccessEffect(customerValidationState, data => {
        switch (data.response) {
            case AuthValidationSuccessResponse.LINK_LOGIN:
                setLinkLoginData({
                    email: data.email,
                    operatorName: data.operatorName,
                });
                break;
            case AuthValidationSuccessResponse.LOGGED_IN:
                navigate(`/${data.tenantId}`);
                break;
        }
    });

    useServerErrorEffect(customerValidationState, (_, data) => {
        if (data === null) {
            setValidationState(ValidationState.GENERIC_ERROR);
        } else {
            switch (data.reason) {
                case AuthValidationErrorReason.EMAIL_NOT_VALID:
                    setValidationState(ValidationState.EMAIL_NOT_VALID);
                    break;
                case AuthValidationErrorReason.ALREADY_REGISTERED_ON_OPERATOR:
                    setValidationState(
                        ValidationState.ALREADY_REGISTERED_ON_OPERATOR,
                    );
                    break;
            }
        }
    });

    if (tenantId === null) {
        return <Navigate to="/" replace />;
    }

    if (linkLoginData !== null) {
        return (
            <LinkLoginPopup
                linkLoginData={linkLoginData}
                onConfirm={() =>
                    createAndLinkTenantCustomerToLogin({
                        tenantId: tenantId,
                    })
                }
                linkCustomerToLoginState={
                    createAndLinkTenantCustomerToLoginState
                }
                onAbort={() => logout()}
            />
        );
    }

    return (
        <AuthedValidationContent
            validationState={validationState}
            origin={ValidationOrigin.TENANT}
        />
    );
}
