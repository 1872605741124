import { Box, Theme } from '@mui/material';

import { SvgIcon } from 'dg-web-shared/ui/icons/SvgIcon';
import { envIsProduction } from 'dg-web-shared/lib/Environment.ts';
import { ParkingaboLink } from '../ParkinaboLink.tsx';
import { ParkingaboButton } from './ParkingaboButton.tsx';
import { Settings } from '@mui/icons-material';
import { To } from 'react-router-dom';

export function ParkingaboLogoHeader() {
    return (
        <Box
            sx={theme => ({
                background: getHeaderBackgroundColor(theme),
                paddingLeft: theme.spacing(3),
                paddingTop: theme.spacing(4),
                paddingBottom: theme.spacing(4),
            })}
        >
            <ParkingaboLogo width={313} height={66} />
        </Box>
    );
}

export function ParkingaboLogoHeaderCompact({
    settingsLinkTo,
}: {
    settingsLinkTo?: To;
}) {
    return (
        <Box
            sx={theme => ({
                display: 'flex',
                width: '100%',
                justifyContent: 'space-between',
                alignItems: 'center',
                backgroundColor: getHeaderBackgroundColor(theme),
                color: theme.palette.primary.contrastText,
                padding: '29px 32px 29px 26px',
            })}
        >
            <ParkingaboLogo width={237} height={50} />
            {settingsLinkTo && (
                <ParkingaboLink to={settingsLinkTo}>
                    <ParkingaboButton
                        sx={{
                            width: 40,
                            height: 40,
                            minWidth: 40,
                            backgroundColor: theme =>
                                theme.palette.primary.contrastText,
                            ['&:hover']: {
                                backgroundColor: theme =>
                                    theme.palette.primary.light,
                            },
                        }}
                    >
                        <Settings />
                    </ParkingaboButton>
                </ParkingaboLink>
            )}
        </Box>
    );
}

function ParkingaboLogo({ width, height }: { width: number; height: number }) {
    return (
        <Box
            sx={{
                width: width,
                height: height,
            }}
        >
            <SvgIcon icon={'parkingabo'} width={2473} height={522} />
        </Box>
    );
}

function getHeaderBackgroundColor(theme: Theme) {
    return envIsProduction()
        ? theme.palette.primary.main
        : theme.palette.warning.light;
}
