import { CssBaseline } from '@mui/material';

import { AppRoutes, useLogin } from './routes/AppRoutes';
import { MainLayout } from './MainLayout';
import {
    LanguageProvider,
    useLanguage,
} from 'dg-web-shared/common/hooks/LanguageProvider';
import { ParkingaboThemeProvider } from './shared/ParkingaboThemeProvider';
import { envIsLocalDev } from 'dg-web-shared/lib/Environment.ts';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { LocalizationProvider } from '@mui/x-date-pickers';

export function App() {
    return (
        <LanguageProvider>
            <ActualApp />
        </LanguageProvider>
    );
}

function ActualApp() {
    const login = useLogin();
    const { language } = useLanguage();

    if (envIsLocalDev()) {
        document.title = 'LOCAL Parkingabo';
    }

    return (
        <ParkingaboThemeProvider>
            <CssBaseline>
                <LocalizationProvider
                    adapterLocale={language}
                    dateAdapter={AdapterLuxon}
                >
                    <MainLayout>
                        <AppRoutes login={login} />
                    </MainLayout>
                </LocalizationProvider>
            </CssBaseline>
        </ParkingaboThemeProvider>
    );
}
