import { Box, Typography, TextField } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Localized } from 'dg-web-shared/common/hooks/LanguageProvider';
import { ParkingaboLink } from '../../../components/ParkinaboLink';
import { useParkingaboServerWrite } from '../../../api/ParkingaboApi';
import {
    useNavigateOnSuccess,
    RequestMethod,
    RequestStatus,
    useServerSuccessEffect,
} from 'dg-web-shared/lib/hooks/ServerStateHooks';
import { ParkingaboLayoutWithHeader } from '../../../components/layout/ParkingaboLayoutWithHeader';
import { useRequestEmailChange } from '../../../hooks/UseRequestEmailChange';
import { ParkingaboButton } from '../../../components/layout/ParkingaboButton';
import { ParkingaboUser } from '../../../shared/ParkingaboModels';
import { useParkingaboAuthedPathGeneration } from '../../RouteUtils';
import { MouseEvent } from 'react';

export function EmailChangePending({
    user,
    refetchUser,
}: {
    user: ParkingaboUser;
    refetchUser: () => void;
}) {
    const theme = useTheme();
    const generateAuthedParkingaboPath = useParkingaboAuthedPathGeneration();
    const [resendState, resendLink] = useRequestEmailChange();
    const [cancelState, cancelEmailChange] = useParkingaboServerWrite<
        void,
        void
    >(
        () => ({
            url: 'user/self/request-email-change',
            method: RequestMethod.DELETE,
        }),
        true,
    );

    useServerSuccessEffect(cancelState, refetchUser);
    useNavigateOnSuccess(
        resendState,
        generateAuthedParkingaboPath('settings/user/email-link-resent'),
    );

    return (
        <ParkingaboLayoutWithHeader
            backTo={generateAuthedParkingaboPath('settings/user')}
            title={
                <Localized
                    de="E-Mail-Änderungen pendent"
                    fr="Changement e-mail en attente"
                    it="Modifica e-mail in sospeso"
                    en="E-mail change pending"
                />
            }
        >
            <Box>
                <TextField
                    value={user.email}
                    style={{ marginBottom: theme.spacing(3) }}
                    disabled={true}
                    label={
                        <Localized
                            de="Aktuelle E-Mail-Adresse"
                            fr="Adresse e-mail actuelle"
                            it="Indirizzo e-mail attuale"
                            en="Current e-mail address"
                        />
                    }
                />
                <TextField
                    value={user.requestedEmail || ''}
                    style={{ marginBottom: theme.spacing(3) }}
                    disabled={true}
                    label={
                        <Localized
                            de="Neue E-Mail-Adresse"
                            fr="Nouvelle adresse e-mail"
                            it="Nuovo indirizzo e-mail"
                            en="New e-mail address"
                        />
                    }
                />
                <Typography style={{ marginBottom: theme.spacing(3) }}>
                    <Localized
                        de="Sie haben Ihre neue E-Mail-Adresse noch nicht bestätigt; dafür hatten wir Ihnen ein Bestätigungslink per E-Mail geschickt."
                        fr="Vous n'avez pas encore confirmé votre nouvelle adresse e-mail; nous vous avons envoyé par e-mail le lien de confirmation."
                        it="Non ha ancora confermato il suo nuovo indirizzo e-mail; a questo scopo le abbiamo inviato per e-mail il link di conferma."
                        en="You have not yet confirmed your new e-mail address; for this purpose we have sent you by e-mail the confirmation link."
                    />
                </Typography>
                <Typography style={{ marginBottom: theme.spacing(3) }}>
                    <Localized
                        de="Sollten Sie diese E-Mail noch nicht erhalten haben, überprüfen Sie in Ihrem E-Mail-Programm den Spam-Ordner. Ansonsten können Sie hier "
                        fr="Si vous n'avez pas encore reçu cet e-mail, veuillez également vérifier votre dossier spam dans le programme de messagerie. Sinon, vous pouvez "
                        it="Se non ha ancora ricevuto questo e-mail, verifichi per favore la sua cartella spam (posta indesiderata) nel programma di posta elettronica. Altrimenti può richiedere qui "
                        en="Please also check your spam folder in your e-mail program, if you have not received this e-mail yet. Otherwise, you can "
                    />
                    <ParkingaboLink
                        to=""
                        onClick={(e: MouseEvent) => {
                            e.preventDefault();
                            resendLink({ email: user.requestedEmail });
                        }}
                    >
                        <Localized
                            de="einen neuen Bestätigungslink anfordern."
                            fr="demander un nouveau lien de confirmation ici."
                            it="un nuovo link di conferma."
                            en="request here a new confirmation link."
                        />
                    </ParkingaboLink>
                </Typography>
                <ParkingaboButton
                    variant="contained"
                    style={{
                        width: 'auto',
                        float: 'right',
                    }}
                    onClick={() => cancelEmailChange()}
                    loading={cancelState.status === RequestStatus.PENDING}
                >
                    <Localized
                        de="Änderung verwerfen"
                        fr="Rejeter la modification"
                        it="Annulla modifica"
                        en="Discard change"
                    />
                </ParkingaboButton>
            </Box>
        </ParkingaboLayoutWithHeader>
    );
}
