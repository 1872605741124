import { OverridableComponent } from '@mui/material/OverridableComponent';
import {
    Box,
    Card,
    CardActionArea,
    Chip,
    SvgIconTypeMap,
    Typography,
} from '@mui/material';
import { ParkingaboLink } from '../ParkinaboLink.tsx';
import { ChevronRight } from '@mui/icons-material';
import { ReactNode } from 'react';

export function ParkingaboCardButton({
    to,
    label,
    Icon,
    counter,
}: {
    to: string;
    label: ReactNode;
    Icon?: OverridableComponent<SvgIconTypeMap>;
    counter?: number;
}) {
    return (
        <ParkingaboLink to={to} replace={false} underline="none">
            <Card elevation={0}>
                <CardActionArea
                    sx={theme => ({
                        display: 'flex',
                        justifyContent: 'space-between',
                        backgroundColor: theme.palette.background.default,
                        paddingX: theme.spacing(2),
                        paddingY: theme.spacing(3),
                    })}
                >
                    <Box
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            overflow: 'hidden',
                        }}
                    >
                        {Icon && (
                            <Icon
                                sx={theme => {
                                    const h3FontSize =
                                        theme.typography.h3.fontSize;
                                    return {
                                        fontSize:
                                            typeof h3FontSize === 'number'
                                                ? h3FontSize * 2
                                                : theme.typography.h2.fontSize,
                                    };
                                }}
                            />
                        )}
                        <Typography
                            variant="h3"
                            component="h2"
                            sx={{
                                marginLeft: theme => theme.spacing(1),
                                textTransform: 'none',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                            }}
                        >
                            {label}
                        </Typography>
                    </Box>
                    <Box style={{ display: 'flex', alignItems: 'center' }}>
                        {Boolean(counter) && (
                            <Chip
                                label={counter}
                                color="primary"
                                sx={{
                                    marginRight: theme => theme.spacing(1),
                                    minWidth: 24,
                                    height: 24,
                                }}
                            />
                        )}
                        <ChevronRight />
                    </Box>
                </CardActionArea>
            </Card>
        </ParkingaboLink>
    );
}
