import { Navigate, Outlet } from 'react-router-dom';
import { CustomerTenantContextData } from '../routes/RouteUtils';
import { createContext, useContext } from 'react';
import { useParamOrNull } from 'dg-web-shared/lib/ReactRouterHelpers.ts';
import { ParkingaboUser, Tenant } from '../shared/ParkingaboModels.tsx';
import { ParkingaboAsyncLoadedSection } from './layout/ParkingaboAsyncLoadedSection.tsx';
import { ParkingaboProductListItem } from '../shared/ParkingaboProductModels.ts';
import { ParkingaboVehicle } from 'dg-web-shared/common/models/Vehicle.tsx';
import { useServerFetch } from 'dg-web-shared/lib/hooks/ServerStateHooks.ts';
import { ValidationData } from 'dg-web-shared/lib/forms/FormValidationHelpers.tsx';

export const CustomerTenantContext =
    createContext<CustomerTenantContextData | null>(null);

export function useParamTenantId(): number | null {
    const paramString = useParamOrNull('tenantId');
    if (!paramString) {
        return null;
    }
    const number = Number.parseInt(paramString, 10);
    return Number.isNaN(number) ? null : number;
}

export function CustomerTenantContextProvider({
    linkedUsers,
    refetchUser,
    logout,
}: {
    linkedUsers: ParkingaboUser[];
    refetchUser: () => void;
    logout: () => void;
}) {
    const tenantId = useParamTenantId();
    const user = linkedUsers.find(user => user.tenantId === tenantId);

    if (!user) {
        return <Navigate to="/" replace />;
    }

    return (
        <ContextProvider
            linkedUserCount={linkedUsers.length}
            user={user}
            refetchUser={refetchUser}
            logout={logout}
        />
    );
}

function ContextProvider({
    linkedUserCount,
    user,
    refetchUser,
    logout,
}: {
    linkedUserCount: number;
    user: ParkingaboUser;
    refetchUser: () => void;
    logout: () => void;
}) {
    const [productState, refetchProducts] = useServerFetch<
        ParkingaboProductListItem[],
        { tenantId: number },
        ValidationData
    >(
        ({ tenantId }) => ({
            url: `/ui-api/parkingabo/tenant/${tenantId}/user/self/product`,
        }),
        { tenantId: user.tenantId },
    );
    const [vehiclesState, refetchVehicles] = useServerFetch<
        ParkingaboVehicle[],
        { tenantId: number },
        ValidationData
    >(
        ({ tenantId }) => ({
            url: `/ui-api/parkingabo/tenant/${tenantId}/user/self/vehicles`,
        }),
        { tenantId: user.tenantId },
    );

    const [tenantState] = useServerFetch<
        Tenant,
        { tenantId: number },
        ValidationData
    >(
        ({ tenantId }) => ({
            url: `/ui-api/parkingabo/tenant/${tenantId}/`,
        }),
        { tenantId: user.tenantId },
    );

    return (
        <ParkingaboAsyncLoadedSection
            state={tenantState}
            render={tenant => (
                <ParkingaboAsyncLoadedSection
                    state={productState}
                    render={products => (
                        <ParkingaboAsyncLoadedSection
                            state={vehiclesState}
                            render={vehicles => {
                                return (
                                    <CustomerTenantContext.Provider
                                        value={{
                                            user: user,
                                            linkedUserCount: linkedUserCount,
                                            refetchUser: refetchUser,
                                            products: products,
                                            refetchProducts: refetchProducts,
                                            vehicles: {
                                                data: vehicles,
                                                status: vehiclesState.status,
                                                refetch: refetchVehicles,
                                            },
                                            tenant: tenant,
                                            logout: logout,
                                        }}
                                    >
                                        <Outlet />
                                    </CustomerTenantContext.Provider>
                                );
                            }}
                        />
                    )}
                />
            )}
        />
    );
}

export function useCustomerTenant(): CustomerTenantContextData {
    const context = useContext(CustomerTenantContext);
    if (!context) {
        throw new Error('useCustomerTenant must be used within the context');
    }
    return context;
}
