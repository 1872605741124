import { useEffect, useState } from 'react';
import { Localized } from 'dg-web-shared/common/hooks/LanguageProvider';
import { ParkingaboLayoutWithHeader } from '../../../components/layout/ParkingaboLayoutWithHeader';
import { Box } from '@mui/material';
import { ParkingaboAsyncLoadedSection } from '../../../components/layout/ParkingaboAsyncLoadedSection';
import { ParkingaboProductTemplate } from './ParkingaboProductTemplateModels';
import {
    Outlet,
    useNavigate,
    useOutletContext,
    useParams,
} from 'react-router-dom';
import { WizardSelectionState } from './ProductsSelectionOutlet';
import { useParkingaboServerFetch } from '../../../api/ParkingaboApi';
import { ParkingaboRoutedModal } from '../../../components/layout/ParkingaboRoutedModal';
import {
    ParkingaboCityInfo,
    ParkingaboZoneInfo,
} from '../../../shared/ParkingaboProductModels';
import { useForceProductVisibility } from '../../../hooks/UseForceProductVisibility.tsx';

interface WizardProductSelection {
    city: ParkingaboCityInfo | null;
    zone: ParkingaboZoneInfo | null;
    product: ParkingaboProductTemplate | null;
}

interface ProductAddOutletContext {
    productTemplates: ParkingaboProductTemplate[];
    zones: ParkingaboZoneInfo[];
    cities: ParkingaboCityInfo[];
    productSelectionState: WizardSelectionState;
    setProductSelectionState: (value: WizardSelectionState) => void;
    productNotFound: boolean;
    disableProductChange: boolean;
}

export function useProductAddContext() {
    return useOutletContext<ProductAddOutletContext>();
}

function ProductAddOutlet({ context }: { context: ProductAddOutletContext }) {
    return <Outlet context={context} />;
}

interface ParkingaboProductSelectionResponse {
    productTemplates: ParkingaboProductTemplate[];
    zones: ParkingaboZoneInfo[];
}

function getUniqueCities(zones: ParkingaboZoneInfo[]): ParkingaboCityInfo[] {
    const uniqueCitiesObj = zones.reduce(
        (cityObj, zone) => {
            if (!cityObj[zone.city]) {
                cityObj[zone.city] = {
                    zipCode: zone.zipCode,
                    city: zone.city,
                };
            }
            return cityObj;
        },
        {} as { [key: string]: ParkingaboCityInfo },
    );

    return Object.values(uniqueCitiesObj).sort((a, b) =>
        a.city.localeCompare(b.city),
    );
}

export function ProductsAddOutlet() {
    const { contractTemplateId: contractTemplateIdString } = useParams<{
        contractTemplateId?: string;
    }>();
    const forceVisibility = useForceProductVisibility();
    const contractTemplateId = contractTemplateIdString
        ? parseInt(contractTemplateIdString, 10)
        : null;
    const navigate = useNavigate();

    const [productsState] = useParkingaboServerFetch<
        ParkingaboProductSelectionResponse,
        { forceVisibility: boolean }
    >(
        () => ({
            url: `user/self/product/list${
                forceVisibility ? '?forceVisibility=true' : ''
            }`,
        }),
        { forceVisibility },
    );

    const [productNotFound, setProductNotFound] = useState(false);
    const [disableProductChange, setDisableProductChange] = useState(false);

    const [productSelectionState, setProductSelectionState] =
        useState<WizardProductSelection>({
            city: null,
            zone: null,
            product: null,
        });

    useEffect(() => {
        if (contractTemplateId) {
            setDisableProductChange(true);
        }
    }, []);

    useEffect(() => {
        if (contractTemplateId && productsState.data) {
            const product = productsState.data.productTemplates.find(
                template => template.contractTemplateId === contractTemplateId,
            );
            if (product) {
                setProductSelectionState({ ...productSelectionState, product });
            } else {
                setProductNotFound(true);
                navigate('.');
            }
        }
    }, [contractTemplateId, productsState.data]);

    useEffect(() => {
        if (productNotFound && productSelectionState.product) {
            setProductNotFound(false);
            setDisableProductChange(false);
        }
    }, [productSelectionState]);

    return (
        <ParkingaboRoutedModal
            open={true}
            backUrl={'..'}
            render={() => (
                <ParkingaboLayoutWithHeader
                    noGutter
                    backTo={'..'}
                    title={
                        <Localized
                            de="Produkt hinzufügen"
                            fr="Ajouter un produit"
                            it="Aggiungi prodotto"
                            en="Add product"
                        />
                    }
                >
                    <ParkingaboAsyncLoadedSection
                        state={productsState}
                        render={products => (
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'stretch',
                                    height: '100%',
                                }}
                            >
                                <ProductAddOutlet
                                    context={{
                                        productTemplates:
                                            products.productTemplates,
                                        zones: products.zones,
                                        cities: getUniqueCities(products.zones),
                                        productSelectionState:
                                            productSelectionState,
                                        setProductSelectionState:
                                            setProductSelectionState,
                                        productNotFound: productNotFound,
                                        disableProductChange:
                                            disableProductChange,
                                    }}
                                />
                            </Box>
                        )}
                    />
                </ParkingaboLayoutWithHeader>
            )}
        />
    );
}
