import Alert from '@mui/material/Alert';
import { useTheme } from '@mui/material/styles';

import {
    LoadingSpinnerPresets,
    PresetLoadingSpinner,
} from 'dg-web-shared/common/components/material-ui/PresetLoadingSpinner';
import { Localized } from 'dg-web-shared/common/hooks/LanguageProvider';
import {
    RequestStatus,
    ServerRequestState,
} from 'dg-web-shared/lib/hooks/ServerStateHooks';

export function ParkingaboAsyncLoadedSection<DT, ET>({
    state,
    render,
    renderError,
}: {
    state: ServerRequestState<DT, ET>;
    render: (data: DT) => JSX.Element | null;
    renderError?: (errorData: ET | null) => JSX.Element | null;
}) {
    const theme = useTheme();
    const noData = !state.data;

    if (
        (state.status === RequestStatus.PENDING && noData) ||
        state.status === RequestStatus.NEVER_EXECUTED
    ) {
        return (
            <PresetLoadingSpinner
                preset={LoadingSpinnerPresets.FillAllSpaceAndCenter}
            />
        );
    }

    if (state.status === RequestStatus.ERROR) {
        if (renderError) {
            return renderError(state.data);
        }
        return (
            <Alert
                variant="filled"
                severity="error"
                style={{
                    margin: theme.spacing(3),
                }}
            >
                <Localized
                    de="ERROR - Die Daten konnten nicht geladen werden."
                    fr="ERREUR - Les données n'ont pas pu être téléchargées."
                    it="ERRORE - Non è stato possibile caricare i dati."
                    en="ERROR - Data could not be loaded."
                />
            </Alert>
        );
    }

    if (noData) {
        throw new Error(
            `Request State '${state.status}' with no data should never happen. This should be debugged.`,
        );
    }

    return render(state.data);
}
