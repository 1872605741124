import 'core-js/stable';

import { App } from './App';
import { envIsProduction, envIsTest } from 'dg-web-shared/lib/Environment';
import * as Sentry from '@sentry/browser';
import { createRoot } from 'react-dom/client';

export function initSentry(dsn: string) {
    Sentry.init({
        dsn: dsn,
        integrations: [
            Sentry.captureConsoleIntegration({
                levels: ['error'],
            }),
        ],
    });
}

if (envIsProduction()) {
    initSentry(
        'https://fd1c9c8235734ac4a5d62e134ac69680@o28142.ingest.sentry.io/5856623',
    );
} else if (envIsTest()) {
    initSentry(
        'https://0dba0e9032174065b08c9d8737160701@o28142.ingest.sentry.io/5856621',
    );
}

const container = document.getElementById('app-container');
const root = createRoot(container!);
root.render(<App />);
